/*кнопка звонка*/

.callback {
    position: fixed;
    right: 8%;
    bottom: 5%;
    z-index:999;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.callback-bt {
    background: url('/images/krug.svg') no-repeat center;
    border-radius:50%;
    box-shadow: 0 8px 10px rgba(0, 0, 0, 0.3);
    cursor:pointer;
    position: relative;
    height:68px;
    text-align:center;
    width:68px;
    transition:.3s;
    -webkit-animation:hoverWave linear 1s infinite;
    animation:hoverWave linear 1s infinite;
    display: block;
}

.callback-bt .text-call{
    height:68px;
    width:68px;
    border-radius:50%;
    position:relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.callback span {
    text-align: center;
    color:#000000;
    font-size: 8px;
    line-height: 14px;
    font-weight: 600;
    text-transform: uppercase;
    transition: opacity .3s linear;
}

.callback-bt:hover img {
    color:#000000;
    font-size:40px;
    transition:.3s;
}

.callback-bt img {
    color:#fff;
    font-size:34px;
    transition:.3s;
    line-height: 66px;transition: .5s ease-in-out;
}

.callback-bt img  {
    width: 44px;
    animation: 1200ms ease 0s normal none 1 running shake;
    animation-iteration-count: infinite;
    -webkit-animation: 1200ms ease 0s normal none 1 running shake;
    -webkit-animation-iteration-count: infinite;
}

@-webkit-keyframes hoverWave {
    0% {
        box-shadow:0 8px 10px rgba(0,0,0,0.3),0 0 0 0 rgba(0,0,0,0.2),0 0 0 0 rgba(0,0,0,0.2)
    }
    40% {
        box-shadow:0 8px 10px rgba(0,0,0,0.3),0 0 0 15px rgba(0,0,0,0.2),0 0 0 0 rgba(0,0,0,0.2)
    }
    80% {
        box-shadow:0 8px 10px rgba(0,0,0,0.3),0 0 0 30px rgba(0,0,0,0),0 0 0 26.7px rgba(0,0,0,0.067)
    }
    100% {
        box-shadow:0 8px 10px rgba(0,0,0,0.3),0 0 0 30px rgba(0,0,0,0),0 0 0 40px rgba(0,0,0,0.0)
    }
}@keyframes hoverWave {
     0% {
         box-shadow:0 8px 10px rgba(0,0,0,0.3),0 0 0 0 rgba(0,0,0,0.2),0 0 0 0 rgba(0,0,0,0.2)
     }
     40% {
         box-shadow:0 8px 10px rgba(0,0,0,0.3),0 0 0 15px rgba(0,0,0,0.2),0 0 0 0 rgba(0,0,0,0.2)
     }
     80% {
         box-shadow:0 8px 10px rgba(0,0,0,0.3),0 0 0 30px rgba(0,0,0,0),0 0 0 26.7px rgba(0,0,0,0.067)
     }
     100% {
         box-shadow:0 8px 10px rgba(0,0,0,0.3),0 0 0 30px rgba(0,0,0,0),0 0 0 40px rgba(0,0,0,0.0)
     }
 }

/* animations icon */

@keyframes shake {
    0% {
        transform: rotateZ(0deg);
        -ms-transform: rotateZ(0deg);
        -webkit-transform: rotateZ(0deg);
    }
    10% {
        transform: rotateZ(-30deg);
        -ms-transform: rotateZ(-30deg);
        -webkit-transform: rotateZ(-30deg);
    }
    20% {
        transform: rotateZ(15deg);
        -ms-transform: rotateZ(15deg);
        -webkit-transform: rotateZ(15deg);
    }
    30% {
        transform: rotateZ(-10deg);
        -ms-transform: rotateZ(-10deg);
        -webkit-transform: rotateZ(-10deg);
    }
    40% {
        transform: rotateZ(7.5deg);
        -ms-transform: rotateZ(7.5deg);
        -webkit-transform: rotateZ(7.5deg);
    }
    50% {
        transform: rotateZ(-6deg);
        -ms-transform: rotateZ(-6deg);
        -webkit-transform: rotateZ(-6deg);
    }
    60% {
        transform: rotateZ(5deg);
        -ms-transform: rotateZ(5deg);
        -webkit-transform: rotateZ(5deg);
    }
    70% {
        transform: rotateZ(-4.28571deg);
        -ms-transform: rotateZ(-4.28571deg);
        -webkit-transform: rotateZ(-4.28571deg);
    }
    80% {
        transform: rotateZ(3.75deg);
        -ms-transform: rotateZ(3.75deg);
        -webkit-transform: rotateZ(3.75deg);
    }
    90% {
        transform: rotateZ(-3.33333deg);
        -ms-transform: rotateZ(-3.33333deg);
        -webkit-transform: rotateZ(-3.33333deg);
    }
    100% {
        transform: rotateZ(0deg);
        -ms-transform: rotateZ(0deg);
        -webkit-transform: rotateZ(0deg);
    }
}

@-webkit-keyframes shake {
    0% {
        transform: rotateZ(0deg);
        -ms-transform: rotateZ(0deg);
        -webkit-transform: rotateZ(0deg);
    }
    10% {
        transform: rotateZ(-30deg);
        -ms-transform: rotateZ(-30deg);
        -webkit-transform: rotateZ(-30deg);
    }
    20% {
        transform: rotateZ(15deg);
        -ms-transform: rotateZ(15deg);
        -webkit-transform: rotateZ(15deg);
    }
    30% {
        transform: rotateZ(-10deg);
        -ms-transform: rotateZ(-10deg);
        -webkit-transform: rotateZ(-10deg);
    }
    40% {
        transform: rotateZ(7.5deg);
        -ms-transform: rotateZ(7.5deg);
        -webkit-transform: rotateZ(7.5deg);
    }
    50% {
        transform: rotateZ(-6deg);
        -ms-transform: rotateZ(-6deg);
        -webkit-transform: rotateZ(-6deg);
    }
    60% {
        transform: rotateZ(5deg);
        -ms-transform: rotateZ(5deg);
        -webkit-transform: rotateZ(5deg);
    }
    70% {
        transform: rotateZ(-4.28571deg);
        -ms-transform: rotateZ(-4.28571deg);
        -webkit-transform: rotateZ(-4.28571deg);
    }
    80% {
        transform: rotateZ(3.75deg);
        -ms-transform: rotateZ(3.75deg);
        -webkit-transform: rotateZ(3.75deg);
    }
    90% {
        transform: rotateZ(-3.33333deg);
        -ms-transform: rotateZ(-3.33333deg);
        -webkit-transform: rotateZ(-3.33333deg);
    }
    100% {
        transform: rotateZ(0deg);
        -ms-transform: rotateZ(0deg);
        -webkit-transform: rotateZ(0deg);
    }
}
/* конец кнопки звонка */

@keyframes pulse {
    0% {
        transform: scale(0.5);
        opacity: 0;
    }
    50% {
        opacity: 0.7;
    }
    100% {
        transform: scale(1.6);
        opacity: 0;
    }
}

@keyframes pulse-mobile {
    0% {
        transform: scale(0.5);
        opacity: 0;
    }
    30% {
        opacity: 0.7;
    }
    100% {
        transform: scale(1);
        opacity: 0;
    }
}


.autoflash{
    overflow: hidden;
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
}

.flash{
    content: "";
    background-color: rgba(255, 255, 255, 0.5);
    height: 100%;
    width: 3em;
    display: block;
    position: absolute;
    top: 0;
    left: -4.5em;
    -webkit-transform: skewX(-45deg) translateX(0);
    transform: skewX(-45deg) translateX(0);
    -webkit-transition: none;
    transition: none;
}

.lighting {
    webkit-animation: moving 3s ease-in-out infinite;
    -moz-animation: moving 3s ease-in-out infinite;
    -ms-animation: moving 3s ease-in-out infinite;
    -o-animation: moving 3s ease-in-out infinite;
    animation: moving 3s ease-in-out infinite;
    
}

@keyframes moving {
    30% {  webkit-transform: skewX(-45deg) translateX(33.5em);
        transform: skewX(-45deg) translateX(33.5em);
        
    }
    
    100% {  webkit-transform: skewX(-45deg) translateX(33.5em);
        transform: skewX(-45deg) translateX(33.5em);
        
    }
}