.need {
	padding-top: 226px;

	&-title {
		text-align: center;
		font-weight: 600;
		font-size: 24px;
	}

	&__title {
		margin-bottom: 87px;
	}

	&-block {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		&-title {
			font-weight: 500;
			font-size: 20px;
		}

		&__title {
			margin-bottom: 20px;
		}

		&-desc {
			font-size: 14px;
		}

		&-item {
			background: linear-gradient(0deg, rgba(255, 198, 96, 0.5), rgba(255, 198, 96, 0.5)), linear-gradient(0deg, #FEB807, #FEB807), linear-gradient(0deg, rgba(255, 9, 9, 0.1), rgba(255, 9, 9, 0.1)), linear-gradient(269.27deg, rgba(255, 213, 89, 0.8) 37.34%, rgba(255, 216, 95, 0) 100.05%), linear-gradient(0deg, rgba(255, 198, 96, 0.5), rgba(255, 198, 96, 0.5));
			border-radius: 10px;
			padding: 38px 31px;
			height: 356px;
			margin-bottom: 18px;
			display: flex;
			box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.1);
			flex-direction: column;
			position: relative;
			overflow: hidden;

			&._short {
				width: 463px;
			}

			&._long {
				width: 717px;
			}

			&._black {
				color: #FFFFFF;
				background: radial-gradient(209.65% 107.58% at 49.89% 93.96%, rgba(196, 196, 196, 0.2) 0%, rgba(255, 255, 255, 0) 100%), #000000;

				.need-block-btn {
					border-color: #FFFFFF;
					color: #FFFFFF;
				}
			}

			&._white {
				background: radial-gradient(270.01% 138.53% at 49.84% 17.66%, rgba(196, 196, 196, 0.3) 0%, rgba(255, 255, 255, 0) 100%), #E4E4E4;
			}

			&:after {
				content: '';
				position: absolute;
				background-position: center;
				background-repeat: no-repeat;
				height: 356px;
				right: 0;
				bottom: 0;
			}

			&:nth-child(1) {
				.need-block-desc {
					width: 225px;
				}

				&:after {
					width: 185px;
					background-image: url('/images/need-block-item-1.png');
				}
			}

			&:nth-child(2) {
				.need-block-desc {
					width: 245px;
				}

				&:after {
					width: 513px;
					background-image: url('/images/need-block-item-2.png');
				}
			}

			&:nth-child(3) {
				.need-block-desc {
					width: 210px;
				}

				&:after {
					width: 702px;
					background-image: url('/images/need-block-item-3.png');
				}
			}

			&:nth-child(4) {
				.need-block-desc {
					width: 250px;
				}

				&:after {
					width: 376px;
					background-image: url('/images/need-block-item-4.png');
				}
			}
		}

		&-btn {
			margin-top: auto;
			margin-right: auto;
			position: relative;
			z-index: 1;
		}
	}
}

@media (max-width: 800px) {
	.need {
		padding-top: 100px;

		&-title {
			margin-bottom: 50px;
			font-size: 26px;
		}

		&-block {
			&-item {
				padding: 20px 25px;
				margin-bottom: 50px;
				box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.3);

				&._short {
					width: 100%;
				}

				&:after {
					background-size: contain;
				}

				&:nth-child(1):after {
					height: 271px;
					right: -97px;
				}

				&:nth-child(2):after {
					width: 258px;
					height: 298px;
					bottom: -122px;
				}

				&:nth-child(3):after {
					width: 483px;
					height: 342px;
					bottom: -125px;
				}

				&:nth-child(4):after {
					width: 319px;
					height: 416px;
					bottom: -125px;
				}
			}

			&__title {
				font-weight: 600;
				font-size: 18px;
			}

			&-btn.btn-details {
				background-color: transparent;
				margin-top: 20px;
			}
		}
	}
}