.modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
	margin: auto;
	z-index: 1000;
	display: none;;

	&__title {
		font-weight: bold;
		font-size: 30px;
	}

	&__desc {
		margin: 17px 0;

		span {
			color: #CD8A20;
		}
	}

	&__btn {
		margin: 16px 0;

		&:hover {
			margin: 18px 0 14px;
		}
	}

	&__close {
		position: absolute;
		right: 15px;
		top: 15px;
		cursor: pointer;
		z-index: 100;

		&:after {
			content: '\2573';
		}
	}
}

@media (max-width: 800px) {
	.modal {
		&__title {
			font-size: 24px;
			text-align: center;
			margin: 0 -10px;
		}

		&__desc {
			font-size: 12px;
			text-align: center;
			margin: 5px 0 15px;
			padding: 0 10px;
		}

		&__btn {
			margin: 10px 0;
		}
	}
}