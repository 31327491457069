.contacts {
	background: url(/images/map-bg.png) no-repeat center;
	height: 1080px;
	padding-top: 90px;

	&__block {
		margin-top: 160px;
	}

	&-block {
		width: 452px;
		height: 563px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding: 98px 97px 92px 95px;
		background: url('/images/contacts-bg.png') no-repeat center;
		
		&__title {
			font-weight: bold;
			font-size: 32px;
		}

		&__item {
			padding-left: 30px;
			margin-bottom: 18px;

			&-subtitle {
				font-size: 18px;
				color: #555555;
			}

			&-title {
				text-decoration: none;
				color: #202020;
			}

			&._address {
				background: url('/images/address.svg') no-repeat left top 2px;

				.contacts-block__item-title {
					font-size: 25px;
				}
			}

			&._phone {
				background: url('/images/phone.svg') no-repeat left top 5px;

				.contacts-block__item-title {
					font-weight: 600;
					font-size: 30px;
				}
			}

			&._mail {
				background: url('/images/mail.svg') no-repeat left top 5px;

				.contacts-block__item-title {
					font-size: 22px;
				}
			}
		}
	}

	&__social {
		display: flex;
		width: 174px;
		justify-content: space-between;
		margin-left: 140px;

		&-item {
			width: 30px;
			height: 30px;
			background-position: center;
			background-repeat: no-repeat;

			&._inst {
				background-image: url('/images/inst.svg');
			}

			&._tg {
				background-image: url('/images/tg.svg');
			}

			&._vk {
				background-image: url('/images/vk.svg');
			}

			&._whatsapp {
				background-image: url('/images/whatsapp.svg');
			}
		}
	}
}

.page__contacts {
	margin-top: 60px;
}

@media (max-width: 800px) {
	.contacts {
		background: url(/images/map-bg-m.png) no-repeat top;
		height: 1220px;

		&-block {
			width: initial;
			padding: 80px 50px 80px 60px;
			height: 483px;
			background: url(/images/contacts-bg-m.png) no-repeat center;

			&__title {
				font-size: 26px;
			}

			&__item {
				&._address {
					.contacts-block__item-title {
						font-size: 22px;
					}
					.contacts-block__item-subtitle {
						font-size: 16px;
					}
				}

				&._phone {
					.contacts-block__item-title {
						font-size: 28px;
					}
					.contacts-block__item-subtitle {
						font-size: 16px;
					}
				}

				&._mail {
					.contacts-block__item-title {
						font-size: 18px;
					}
				}
			}

			&__btn {
				height: 45px;
			}
		}

		&__block {
			margin-top: 20px;
		}

		&__social {
			margin: 0 auto;
		}
	}

	.page__contacts {
		margin-top: 30px;
	}
}