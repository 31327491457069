.septik-quiz-modal {
    .test-wrapper {
        -webkit-box-shadow: 0 0 30px rgba(152, 152, 152, 0.1);
        box-shadow: 0 0 30px rgba(152, 152, 152, 0.1);
        border-radius: 10px;
        background-color: #ffffff;
        overflow: hidden;
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .test-wrapper.predfinals {
        overflow: unset;
    }

    .test__main {
        width: 77%;
		padding: 18px 28px 38px;
		position: relative;
		height: 500px;
    }

    .test__main.final {
        width: 100%;
    }

    .left-block {
        width: 23%;
        -ms-flex-negative: 0;
        flex-shrink: 0;
		padding: 22px 38px 38px 38px;
        background: url("/images/test-bg.jpg") no-repeat center;
        background-size: cover;
        text-align: center;
		border-radius: 10px 0 0 10px;
		height: 500px;
    }

    .left-block-subtitle {
        color: #414141;
        font-size: 16px;
        font-weight: 400;
        text-transform: uppercase;
        margin-bottom: 19px;
	}

	.predfinal .test-text-wrap {
		text-align: left;
	}
	
	.predfinal .left-block-title {
		color: #a8a3a1;
	}

    .left-block-title {
        color: #2a2a2a;
        font-size: 26px;
        font-weight: 700;
		text-transform: uppercase;
		font-weight: bold;
    }

    .left-block-title .trigger {
        color: #44bb5c;
        font-style: italic;
        font-weight: 700;
    }

    .left-block.no-show {
        overflow: hidden;
        width: 0;
        padding: 0;
    }

    .progress-bar {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        flex-direction: column;
        width: 75%;
        margin: 0 auto 10px;
    }

    .progress-bar__title {
        color: #a8a3a1;
        font-size: 18px;
        font-weight: 500;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        margin-bottom: 16px;
    }

    .progress-bar__line {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 19%;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: inset 0 0 20px rgba(49, 233, 63, 0.2);
        background-color: #4bff50;
        background-image: -webkit-gradient(
                linear,
                left top,
                right top,
                from(rgba(88, 222, 92, 0.8)),
                to(rgba(88, 222, 92, 0))
            ),
            repeating-linear-gradient(
                -55deg,
                #58de5c,
                transparent 6px,
                #58de5c 5px,
                #58de5c 5px
            );
        background-image: -webkit-linear-gradient(
                left,
                rgba(88, 222, 92, 0.8) 0%,
                rgba(88, 222, 92, 0) 100%
            ),
            -webkit-repeating-linear-gradient(145deg, #58de5c, transparent 6px, #58de5c
                        5px, #58de5c 5px);
        background-image: -o-linear-gradient(
                left,
                rgba(88, 222, 92, 0.8) 0%,
                rgba(88, 222, 92, 0) 100%
            ),
            -o-repeating-linear-gradient(145deg, #58de5c, transparent 6px, #58de5c
                        5px, #58de5c 5px);
        background-image: linear-gradient(
                to right,
                rgba(88, 222, 92, 0.8) 0%,
                rgba(88, 222, 92, 0) 100%
            ),
            repeating-linear-gradient(
                -55deg,
                #58de5c,
                transparent 6px,
                #58de5c 5px,
                #58de5c 5px
            );
        animation: progres 2.8s linear infinite;
        transition: width 0.4s;
    }

    .progress-bar__line-wrap {
        height: 8px;
        box-shadow: 0 0 30px rgba(183, 193, 199, 0.1);
        border-radius: 5px;
        background-color: #e7e7e7;
        width: 100%;
        overflow: hidden;
        position: relative;
    }

    @-webkit-keyframes progres {
        from {
            background-position: 0 0, 0 0;
        }

        to {
            background-position: 0 0, 60px 30px;
        }
    }

    @keyframes progres {
        from {
            background-position: 0 0, 0 0;
        }

        to {
            background-position: 0 0, 60px 30px;
        }
    }

    .checkbox-psevdo {
        width: 24px;
        height: 24px;
        border-radius: 3px;
        border: 1px solid #a8a3a1;
        background-color: #ffffff;
        padding: 3.8px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 13.3px;
        flex-shrink: 0;
    }

    .checkbox-psevdo-active {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-transform: scale(0);
        -ms-transform: scale(0);
        transform: scale(0);
        -webkit-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
    }

    .checkbox-psevdo-active img {
        width: 140%;
        max-width: 140%;
        -ms-flex-item-align: center;
        align-self: center;
    }

    .checkbox-inp:checked ~ .radio-label {
        color: #2a2a2a;
    }

    .checkbox-inp:checked ~ label .checkbox-psevdo-active {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
    }

    .radio-wrap {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        position: relative;
        margin-top: 2vh;
        margin-bottom: 3vh;
        width: 100%;
        font-weight: 500;
    }

    .radio-item {
        width: 45%;
        text-align: left;
    }

    .radio-label {
        color: #000000;
        font-size: 17px;
        display: inline-block;
        position: relative;
        -webkit-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        cursor: pointer;
        text-align: center;
        justify-content: center;
        margin-top: 9.5px;
    }

    .radio-psevdo {
        border: 1px solid #a8a3a1;
        background-color: #ffffff;
        width: 22.8px;
        height: 22.8px;
        border-radius: 100%;
        -webkit-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        margin-right: 11.4px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-flex: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
    }

    .radio-psevdo-active {
        width: 12.16px;
        height: 12.16px;
        background-color: #44bb5c;
        border-radius: 100%;
        opacity: 0;
        -webkit-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
    }

    .radio-block {
        width: 100%;
    }

    .radio-btn {
        display: none;
    }

    .radio-btn:checked ~ .radio-label {
        color: #2a2a2a;
    }

    .radio-btn:checked ~ label .radio-psevdo-active {
        opacity: 1;
    }

    .test-item-block {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .test-item__title {
        color: #212121;
        font-size: 22.8px;
        font-weight: 700;
        margin-bottom: 32.3px;
    }

    .test-item__title span {
        color: #ffd535;
    }

    .img-label {
        height: 121.6px;
        width: 100%;
        display: inline-block;
        border-radius: 10px;
        cursor: pointer;
    }

    .test_elem {
        -webkit-transform: all 0.4s;
        -ms-transform: all 0.4s;
        transform: all 0.4s;
        border-radius: 4px;
        width: 22%;
        margin: 0 1.5% 28.5px 1.5%;
        display: block;
        position: relative;
        cursor: pointer;
        -webkit-transition: all 0.4s;
        -o-transition: all 0.4s;
        transition: all 0.4s;
    }

    .test_elem-t2 {
        padding: 0;
        border-radius: 10px;
        text-align: center;
        display: block;
    }

    .qw1-bg {
        width: 190px;
        height: 190px;
        max-width: 182px;
        max-height: 186px;
        background: url("/images/1-1.jpg") no-repeat top;
        border: 1px solid #c4c4c4;
        border-radius: 5px;
    }

    label[for="qw1-2"] .qw1-bg {
        background-image: url("/images/1-2.jpg");
    }

    label[for="qw1-3"] .qw1-bg {
        background-image: url("/images/1-3.jpg");
    }

    label[for="qw1-4"] .qw1-bg {
        background-image: url("/images/1-4.jpg");
    }

    label[for="qw2-1"] .qw1-bg {
        background-image: url("/images/2-1.jpg");
    }

    .test__main.qw3 {
        background: url("/images/3-bg.jpg") no-repeat right bottom;
    }

    label[for="qw2-2"] .qw1-bg {
        background-image: url("/images/2-2.jpg");
    }

    label[for="qw2-3"] .qw1-bg {
        background-image: url("/images/2-3.jpg");
    }

    label[for="qw2-4"] .qw1-bg {
        background-image: url("/images/2-4.jpg");
    }

    label[for="qw4-1"] .qw1-bg {
        background-image: url("/images/4-1.jpg");
    }

    label[for="qw4-2"] .qw1-bg {
        background-image: url("/images/4-2.jpg");
    }

    label[for="qw4-3"] .qw1-bg {
        background-image: url("/images/4-3.jpg");
    }

    label[for="qw5-1"] .qw5-bg {
        background-image: url("/images/5-1.jpg");
        background-size: 190px;
    }

    label[for="qw5-2"] .qw5-bg {
        background-image: url("/images/5-2.jpg");
        background-position-y: 34.2px;
        background-size: 133px;
    }

    label[for="qw5-3"] .qw5-bg {
        background-image: url("/images/5-3.jpg");
        background-position-y: 28.5px;
        background-size: 152px;
    }

    .checkbox-wrap {
        padding: 9.5px;
    }

    .test-text-wrap {
        width: 100%;
        text-align: center;
    }

    .test__wrap {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }

    .test__qwiz {
        width: 75%;
    }

    .test__qwiz.active {
        width: 100%;
    }

    .test__gifts {
        -ms-flex-item-align: start;
        align-self: flex-start;
        text-align: center;
        width: 23%;
        padding: 38px;
        -webkit-box-shadow: inset 0 -2px 3px rgba(255, 255, 255, 0.16);
        box-shadow: inset 0 -2px 3px rgba(255, 255, 255, 0.16);
        border-radius: 4px;
        background-color: #ffd535;
        background-image: -webkit-gradient(
            linear,
            left bottom,
            left top,
            from(rgba(255, 255, 255, 0)),
            to(rgba(255, 255, 255, 0.4))
        );
        background-image: -webkit-linear-gradient(
            bottom,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.4) 100%
        );
        background-image: -o-linear-gradient(
            bottom,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.4) 100%
        );
        background-image: linear-gradient(
            to top,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.4) 100%
        );
    }

    .test__gifts.not-active {
        width: 0;
    }

    .test__gifts-subtitle {
        text-shadow: 0 1px 0 rgba(255, 251, 233, 0.8);
        color: #000000;
        font-size: 19px;
        margin-bottom: 7.6px;
        font-weight: 900;
    }

    .test__gifts-title {
        text-shadow: 0 1px 0 rgba(255, 251, 233, 0.8);
        color: #000000;
        font-size: 40px;
        font-weight: 900;
        text-transform: uppercase;
        margin-bottom: 38px;
        line-height: 1.2;
    }

    .test__gifts-img {
        margin-bottom: 11.4px;
    }

    .test-inp-wrap {
        width: 21%;
        margin-right: 3%;
    }

    .test-inp {
        margin-bottom: 21px;
    }

    .test-block-img {
        width: 67%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        height: 365px;
    }

    .test-block-img img {
        -webkit-transform: scale(1.2);
        -ms-transform: scale(1.2);
        transform: scale(1.2);
    }

    .q-4 {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        border: 2px solid #96c3e6;
        border-radius: 4px;
        padding: 57px 38px;
    }

    .inp-hiden {
        display: none;
    }

    .test-inp-wrap-big {
        width: 47%;
    }

    .test-file-wrap {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }

    .file-link {
        color: #000000;
        font-size: 17px;
        border-radius: 25px 0 0 25px;
        border: 2px solid #ecf1f9;
        padding: 19px;
        overflow-x: hidden;
        min-height: 68.4px;
        width: 60%;
        white-space: nowrap;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
    }

    .file-btn {
        color: #ffffff;
        font-size: 17px;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 0.8px;
        border-radius: 0 25px 25px 0;
        background-color: #166aac;
        min-height: 68.4px;
        padding: 19px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        width: 40%;
        margin-left: -2px;
        cursor: pointer;
    }

    .q-5 {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        border-radius: 4px;
        border: 2px solid #96c3e6;
        height: 501px;
        padding: 57px;
    }

    .test-block-proc {
        width: 52%;
        text-align: center;
    }

    .test-big-num {
        background-image: -webkit-gradient(
            linear,
            left bottom,
            left top,
            from(rgba(22, 106, 172, 0)),
            to(#166aac)
        );
        background-image: -webkit-linear-gradient(
            bottom,
            rgba(22, 106, 172, 0) 0%,
            #166aac 100%
        );
        background-image: -o-linear-gradient(
            bottom,
            rgba(22, 106, 172, 0) 0%,
            #166aac 100%
        );
        background-image: linear-gradient(
            to top,
            rgba(22, 106, 172, 0) 0%,
            #166aac 100%
        );
        color: #ffffff;
        font-size: 209px;
        font-weight: 900;
        line-height: 1;
        letter-spacing: -19px;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .test-big-num span {
        font-size: 152px;
    }

    .test-big-num-title {
        color: #000000;
        font-size: 17px;
        font-weight: 900;
        text-transform: uppercase;
        letter-spacing: 0.8px;
    }

    .test-elem-lg {
        width: 30.1%;
    }

    .img-label-gift {
        width: 100%;
        border-radius: 4px 4px 0 0;
        background-color: #edf7fd;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        padding: 19px;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .test-elem-lg .img-label-gift {
        height: 304px;
    }

    .text-price {
        color: #166aac;
        font-size: 19px;
        font-weight: 900;
        white-space: nowrap;
    }

    .test-gift-wrap {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }

    .test-gift-price {
        margin-left: 38px;
        text-align: right;
    }

    .load-text {
        color: #212121;
        font-size: 22.8px;
        font-weight: 600;
    }

    .troe {
        white-space: nowrap;
        overflow-x: hidden;
        display: inline-block;
    }

    .load-text {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-animation: opas 2.5s infinite linear;
        animation: opas 2.5s infinite linear;
    }

    .load {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        flex-wrap: wrap;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .load-img {
        width: 76px;
        margin-bottom: 19px;
        -webkit-animation: deg 2.5s infinite linear;
        animation: deg 2.5s infinite linear;
    }

    .q-7 {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }

    .q-7 .test-block-img {
        width: 46%;
    }

    .q-7 .test-inp-wrap-big {
        width: 41%;
    }

    .final-label {
        border-radius: 32px;
        border: 2px solid #ecf1f9;
        padding: 15.2px;
        margin-bottom: 19px;
        margin-left: 0;
    }

    .inp-final {
        position: relative;
        padding-left: 57px;
    }

    .inp-final:focus {
        border: 2px solid #96c3e6;
    }

    .final-inp-be {
        position: relative;
        z-index: 7;
    }

    .final-inp-be:before {
        content: "";
        position: absolute;
        left: 19px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        width: 26.6px;
        height: 17px;
        background: url("../img/test/Phone_copy.png") no-repeat center;
        background-size: 100% 100%;
        z-index: 2;
    }

    .final__btn-wrap {
        position: relative;
    }

    .final__btn-wrap:before {
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        -webkit-transform: translate(130%, -50%);
        -ms-transform: translate(130%, -50%);
        transform: translate(130%, -50%);
        width: 133px;
        height: 133px;
        background: url("../img/test/Shape.png") no-repeat center;
        background-size: 100% 100%;
        z-index: 2;
    }

    .final__btn-wrap .btn-text {
        color: #000000;
    }

    .final__btn-wrap .btn {
        width: 100%;
        min-height: 85.5px;
        padding: 19px;
        margin-top: 28.5px;
    }

    .test__gifts-price {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }

    .test__gifts-price .text-price {
        color: #000000;
    }

    .test__gifts-price .text-small {
        color: #000000;
    }

    .test__gifts-name {
        text-shadow: 0 1px 0 rgba(255, 251, 233, 0.8);
        color: #000000;
        font-size: 19px;
        font-weight: 500;
        text-align: left;
        line-height: 1.4;
    }

    .qw3-block-title {
        font-weight: 600;
        font-size: 23.56px;
    }

    .qw3-block-subtitle {
        font-size: 17.67px;
        margin-top: 9.5px;
        margin-bottom: 57px;
    }

    .qw3-label {
        font-size: 12.73px;
        margin-bottom: 9.5px;
        display: block;
    }

    .qw3 .test__btn-block {
        width: 75%;
        margin: 38px auto 0;
        justify-content: flex-start;
    }

    .qw5-bg + .radio-label {
        margin-top: -57px;
        display: block;
        width: 228px;
    }

    .qw5-bg {
        width: 228px;
        height: 209px;
        max-width: 234px;
        max-height: 206px;
        border: 1px solid #c4c4c4;
        border-radius: 5px;
        background-repeat: no-repeat;
        background-position: top;
    }

    .qw5-bg + .radio-label span {
        color: #525252;
        font-size: 11.4px;
        display: block;
    }

    .qw5 .test_elem {
        width: 30%;
    }

    .test_elem:hover .bg-qw1 {
        border: 1px solid #44bb5c !important;
        -webkit-box-shadow: 0 0 40px rgba(21, 250, 39, 0.2);
        box-shadow: 0 0 40px rgba(21, 250, 39, 0.2);
    }

    .bg-qw1 {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-image: -webkit-gradient(
            linear,
            left top,
            right top,
            from(#ffffff),
            to(rgba(255, 255, 255, 0))
        );
        background-image: -webkit-linear-gradient(
            left,
            #ffffff 0%,
            rgba(255, 255, 255, 0) 100%
        );
        background-image: -o-linear-gradient(
            left,
            #ffffff 0%,
            rgba(255, 255, 255, 0) 100%
        );
        background-image: linear-gradient(
            to right,
            #ffffff 0%,
            rgba(255, 255, 255, 0) 100%
        );
        border-radius: 4px;
        border: 1px solid #a8a3a1;
        -webkit-transition: all 0.4s;
        -o-transition: all 0.4s;
        transition: all 0.4s;
    }

    .checkbox-inp:checked + .qw1-bg,
    .checkbox-inp:checked + .qw5-bg {
        border: 3px solid #44bb5c !important;
        -webkit-box-shadow: 0 0 30px rgba(21, 250, 39, 0.15);
        box-shadow: 0 0 30px rgba(21, 250, 39, 0.15);
	}
	
	.big-title-test {
		color: #424242;
		font-size: 32.3px;
		font-weight: 400;
		margin-bottom: 19px;
	}

    .test-item-block.qw3-block {
        justify-content: flex-start;
        width: 75%;
        margin: 0 auto;
    }

    .predfinal__img {
        position: absolute;
		right: 0;
		bottom: 33px;
		max-width: 551px;
    }

    .predfinal__block {
        margin-bottom: 57px;
    }

    .predfinal .radio-label {
        justify-content: flex-start;
    }

    .predfinal__form {
        display: flex;
        align-items: center;
    }

    .predfinal__form > div {
        position: relative;
    }

    .pred {
        flex: 1 0 auto;
    }

    .btn-predfinal {
        width: 475px;
        min-height: 57px;
        margin: 0 38px;
    }

    .qw3-check.radio-label {
        margin: 38px 0;
        text-align: left;
    }

    .test-elem-7 {
        width: 100%;
        margin: 0;
        height: 34px;
        min-height: 19px;
        line-height: 1.1;
        padding: 0;
    }

    .btn-test {
        border-radius: 15px;
        width: 247px;
        -webkit-transition: all 0.4s;
        -o-transition: all 0.4s;
        transition: all 0.4s;
        min-height: 59px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        text-transform: uppercase;
        justify-content: center;
    }

    .btn-test:disabled {
        opacity: 0.4;
    }

    .btn-arr {
        font-size: 32px;
        display: inline-block;
        line-height: 0.8;
        margin-left: 13px;
        font-weight: 700;
        color: #000000;
        margin-top: -3px;
    }

    .test__btn-block {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .test__numbers {
        position: absolute;
        bottom: 0;
        left: 38px;
        color: #f3f3f3;
        font-size: 171px;
        font-weight: 900;
        -webkit-transform: translateY(38%);
        -ms-transform: translateY(38%);
        transform: translateY(38%);
    }

    .test__qwiz {
        position: relative;
    }
}

@media (max-width: 800px) {
	.septik-quiz-modal {
		height: 100%;

		.left-block {
			display: none;
		}

		.qw3-block-title {
			font-size: 14px;
		}

		.qw3-block-subtitle {
			margin-bottom: 10px;
		}

		label[for="qw5-1"] .qw5-bg {
			background-image: url(/images/5-1.jpg);
			background-size: 160px;
		}

		label[for="qw5-2"] .qw5-bg {
			background-size: 114px;
		}

		label[for="qw5-3"] .qw5-bg {
			background-size: 125px;
		}

		.qw3-label {
			font-size: 8px;
			margin-bottom: 5px;
		}

		.test-wrapper.predfinals {
			overflow-y: scroll;
		}

		.test-wrapper {
			overflow-y: scroll;
			height: 100%;
		}

		.progress-bar__title {
			font-size: 14px;
			margin: 15px 0 10px;
		}

		.test__main {
			padding: 10px 10px 20px 10px;
			width: 100%;
			height: initial;

			&.finals {
				background: none;
			}
		}

		.left-block-title {
			font-weight: 500;
			font-size: 18px;
		}

		.test_elem {
			width: 48%;
			margin: 0 0 15px;
		}

		.qw1-bg {
			width: 100%;
			background-size: cover;
			height: 146px;
		}

		.radio-label {
			font-size: 14px;
			font-weight: 500;
		}

		.test-item-block {
			justify-content: space-between;
		}

		.test__btn-block {
			justify-content: center;
		}

		.range-block {
			width: 100%;
		}

		.qw5-bg {
			width: 100%;
			height: 180px;
		}

		.qw5-bg+.radio-label {
			font-size: 11px;
			width: 100%;
			margin-top: -46px;

			span {
				font-size: 7px;
			}
		}

		.qw5 .test_elem {
			width: 48%;
			margin: 0 0 20px;
		}

		.mb-1 {
			width: 70%;
			margin: 0 auto -35px;
		}

		.big-title-test {
			font-weight: 600;
			font-size: 18px;
			margin-bottom: 10px;
		}

		.predfinal .left-block-title {
			font-size: 14px;
		}

		.predfinal .qw3-check.radio-label {
			margin: 20px 0 10px;
			font-size: 12px;
		}

		.predfinal {
			.test_elem {
				width: 100%;
			}

			.radio-label {
				text-align: left;
			}
		}

		.qw3-check.radio-label {
			margin: 0;
		}

		.predfinal__block {
			margin-bottom: 20px;
		}

		.predfinal__form {
			flex-direction: column;
			align-items: baseline;
		}

		.btn-predfinal {
			margin: 10px 0;
			width: 235px;
		}

		.predfinal__img {
			display: none;
			width: 0;
		}

		.pred-pdf {
			text-align: center;
			font-size: 10px;

			 br {
				 display: none;
			 }
		}
	}
}