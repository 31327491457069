.thanks-modal {
	width: 320px;
	height: 300px;
	background: #FFFFFF;
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
	border-radius: 10px;
	padding: 90px 20px;
	text-align: center;

	.modal__title {
		font-weight: 500;
		font-size: 24px;
		text-align: center;
	}

	.modal__desc {
		margin: 17px 0;
		text-align: center;
		font-size: 18px;
		color: rgba(46,46,46,0.6);
	}
}