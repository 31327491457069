.prize-modal {
	padding: 190px 350px 50px 215px;
	height: 577px;

	&__form {
		padding-right: 50px;
	}

	&[type="luk"] {
		width: 916px;
		background: url('/images/call-modal_bg-luk.png') no-repeat center;
	}

	&[type="skidka"] {
		width: 937px;
		background: url('/images/call-modal_bg-skidka.png') no-repeat center;
	}

	&[type="trubi"] {
		width: 909px;
		background: url('/images/call-modal_bg-trubi.png') no-repeat center;
	}
}

@media (max-width: 800px) {
	.prize-modal {
		height: 539px;
		width: 320px !important;
		padding: 190px 25px 65px;
		background: none !important;

		&__form {
			padding-right: 0;
		}

		&:before {
			content: '';
			position: absolute;
			width: 508px;
			height: 539px;
			z-index: -1;
			left: -85px;
			top: 3px;
			margin: auto;
		}

		&[type="luk"]:before {
			background: url('/images/call-modal_bg-luk-m.png') no-repeat center;
		}

		&[type="skidka"]:before {
			width: 508px;
			background: url('/images/call-modal_bg-skidka-m.png') no-repeat center;
		}
	
		&[type="trubi"]:before {
			width: 508px;
			background: url('/images/call-modal_bg-trubi-m.png') no-repeat center;
		}

		.modal__close {
			top: 130px;
		}
	}
}