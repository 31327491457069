html {
	color: #202020;
	line-height: 1.1875;
	font-family: 'Gilroy';
	font-size: 16px;
	background: #F5F5F5;
}

* {
	outline: none;
	box-sizing: border-box;
}

body {
	margin: 0;

	&.modal-open {
        bottom: 0;
        left: 0;
        position: fixed;
        right: 0;
		top: 0;
		height: 100%;
		overflow: hidden;
    }
}

.container {
	width: 1200px;
	margin: 0 auto;
}

.btn {
	background: radial-gradient(50% 7.43% at 50% 100%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), radial-gradient(67.05% 156.76% at 50% -16.22%, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(180deg, #FFDF6C 0%, rgba(255, 255, 255, 0) 5.73%), linear-gradient(180deg, #FFCC16 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(180deg, rgba(92, 72, 0, 0) 85.94%, rgba(255, 247, 174, 0.142) 100%), #FF9900;
	box-shadow: 0px 3px 0px #ECA843, 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 10px;
	border: none;
	color: #000000;
	font-family: 'Gilroy';
	cursor: pointer;
	font-size: 16px;
	font-weight: 600;
	height: 50px;
	width: 100%;
	transition: all 0.3s;

	&:hover {
		background: radial-gradient(50% 7.43% at 50% 100%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), radial-gradient(67.05% 156.76% at 50% -16.22%, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(180deg, #FFDF6C 0%, rgba(255, 255, 255, 0) 5.73%), linear-gradient(180deg, #FFCC16 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(180deg, rgba(92, 72, 0, 0) 85.94%, rgba(255, 247, 174, 0.142) 100%), #FF9900;
		box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25), 0px 0px 10px rgba(251, 164, 31, 0.2);
		margin: 2px 0 -2px;
	}

	&-big {
		height: 88px;
		padding: 0 44px;

		&__text {
			font-weight: bold;
			font-size: 24px;
		}

		&__subtext {
			font-weight: 400;
			font-size: 16px;
		}
	}
}

.btn-details {
	border: 1px solid #202020;
	border-radius: 8px;
	font-weight: 600;
	background: none;
	height: 38px;
	cursor: pointer;
	padding: 0 39px;
	font-size: 16px;
	font-family: 'Gilroy';
	color: #202020;
	text-decoration: none;
	display: flex;
    align-items: center;
    width: 166px;

	&:hover {
		background: rgba(255, 138, 0, 0.2);
	}
}

.input {
	background: #F7F7F7;
	box-shadow: inset 0px 0px 3px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	border: none;
	width: 100%;
	height: 50px;
	font-size: 16px;
	padding: 15px 20px;
}

.form-group {
	&__label {
		font-size: 12px;
		margin: 0 0 9px 9px;
		display: block;
	}
}

.form-desc {
	font-size: 12px;
	text-align: center;
}

.page {
	&-title {
		font-weight: bold;
		font-size: 36px;
		text-align: center;

		br {
			display: none;
		}
	}

	&-subtitle {
		font-style: italic;
		font-size: 26px;
		text-align: center;
		opacity: 0.8;

		span {
			color: #CD8920;
		}
	}
}

.swiper-button-prev,
.swiper-button-next {
	background: radial-gradient(50% 7.43% at 50% 100%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), radial-gradient(67.05% 156.76% at 50% -16.22%, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(180deg, #FFDF6C 0%, rgba(255, 255, 255, 0) 5.73%), linear-gradient(180deg, #FFCC16 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(180deg, rgba(92, 72, 0, 0) 85.94%, rgba(255, 247, 174, 0.142) 100%), #FF9900;
	box-shadow: 0px 2px 0px #ECA843, 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 50px;
	width: 55px;
	height: 55px;

	&:after {
		content: '\203A';
		color: #202020;
		font-size: 42px;
		font-family: Tahoma;
	}
}

.swiper-button-prev:after {
	content: '\2039';
}

.overlay-bg {
	z-index: 999;
	display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
	background: url('/images/overlay_bg.jpg') no-repeat center;
}

.overlay {
	z-index: 999;
	display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5) no-repeat center;
}


.mobile.mobile, .mobile-flex {
    display: none;
}

.desktop {
	display: block;
}

.desktop-flex {
	display: flex;
}

.error {
	color: #ff0000;
}

@media (max-width: 800px) {
	.container {
		width: 320px;
	}

	.mobile.mobile {
		display: block;
	}

	.mobile-flex {
		display: flex;
	}

	.desktop, .desktop-flex {
		display: none;
	}

	.btn {
		&-big {
			padding: 0 10px;
			height: 69px;

			&__text {
				font-size: 18px;
			}

			&__subtext {
				font-size: 12px;
			}
		}
	}

	.btn-details {
		font-size: 14px;
		background: #FFC660;
		border: 1px solid #202020;
		height: 32px;
		padding: 0 24px;
		width: 122px;
	}

	.page {
		&-title {
			font-size: 24px;

			br {
				display: block;
			}
		}

		&-subtitle {
			font-size: 18px;

			br {
				display: none;
			}
		}
	}

	.swiper-button-prev,
	.swiper-button-next {
		width: 35px;
		height: 35px;
		right: 0;
		top: 60%;

		&:after {
			font-size: 26px;
		}
	}

	.swiper-button-prev {
		left: 0;
	}

	.overlay-bg {
		pointer-events: none;
	}
}