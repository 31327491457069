.test {
	padding-top: 83px;

	&-title {
		font-weight: bold;
		font-size: 36px;
		color: #FFFFFF;
		text-align: center;
	}

	&-subtitle {
		font-style: italic;
		font-size: 26px;
		opacity: 0.8;
		color: #FFFFFF;
		text-align: center;
	}

	&__subtitle {
		margin-bottom: 62px;
	}

	&-block {
		position: relative;
		margin: 0 auto;
		padding: 30px 65px;
		width: 845px;
		height: 512px;
		background: url('/images/test-block.png') no-repeat center;
	
		&-title {
			font-weight: 500;
			font-size: 24px;
			color: #FFFFFF;
		}

		&-wrapper {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			margin: 25px 0 58px;
		}

		&-item {
			width: 231px;
			height: 110px;
			background: radial-gradient(270.01% 138.53% at 49.84% 17.66%, rgba(196, 196, 196, 0.3) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(0deg, #3E3E3E, #3E3E3E), #FDFDFD;
			box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
			border-radius: 10px;
			cursor: pointer;
			color: #FFFFFF;
			font-weight: 500;
			padding: 10px 15px;
			margin-bottom: 10px;
			position: relative;

			&._active,
			&:hover {
				color: #000000;
				background: radial-gradient(50.25% 3.45% at 50% 100%, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%), radial-gradient(67.05% 156.76% at 50% -16.22%, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(180deg, rgba(255, 223, 108, 0.5) 0%, rgba(255, 255, 255, 0) 5.73%), linear-gradient(180deg, #FFB016 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(180deg, rgba(92, 72, 0, 0) 85.94%, rgba(255, 247, 174, 0.142) 100%), #FEAC30;
				box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.25);
			}

			&:after {
				content: '';
				position: absolute;
			}

			&:nth-child(1):after {
				right: -30px;
				top: -20px;
				width: 163px;
				height: 129px;
				background: url('/images/test-block-item-1.png');
			}

			&:nth-child(2):after {
				right: -23px;
				top: -14px;
				width: 172px;
				height: 136px;
				background: url('/images/test-block-item-2.png');
			}

			&:nth-child(3):after {
				right: -30px;
				top: -5px;
				width: 144px;
				height: 130px;
				background: url('/images/test-block-item-3.png');
			}

			&:nth-child(4):after {
				right: -31px;
				top: -25px;
				width: 171px;
				height: 145px;
				background: url('/images/test-block-item-4.png');
			}

			&:nth-child(5):after {
				right: -32px;
				top: -31px;
				width: 196px;
				height: 176px;
				background: url('/images/test-block-item-5.png');
			}

			&:nth-child(6):after {
				right: -61px;
				top: -25px;
				width: 213px;
				height: 145px;
				background: url('/images/test-block-item-6.png');
			}
		}

		&-btn {
			padding: 0 73px;
			position: relative;
			width: 497px;

			&:after {
				content: '';
				position: absolute;
				right: -222px;
				top: -58px;
				width: 381px;
				height: 203px;
				background: url('/images/test-block-btn.png') no-repeat center;
			}
		}

		&-cards {
			font-size: 14px;
			text-align: center;
			color: #FFFFFF;
			opacity: 0.5;
			width: 158px;
			position: absolute;
			right: 50px;
			bottom: 30px;
		}
	}
}

@media (max-width: 800px) {
	.test {
		padding-top: 67px;
		position: relative;

		&:after {
			content: '';
			width: 320px;
			height: 314px;
			position: absolute;
			bottom: -267px;
			background: url(/images/coin-m.png) no-repeat center;
			z-index: 1;
			left: 0;
			right: 0;
			margin: 0 auto;
		}

		&-title {
			font-size: 26px;
		}

		&-subtitle {
			font-size: 20px;

			br {
				display: none;
			}
		}

		&__subtitle {
			margin: 15px 0 50px;
		}

		&-block {
			padding: 25px 15px;
			width: 321px;
			height: 652px;
			position: relative;
    		z-index: 2;
			background: url(/images/test-block-m.png) no-repeat center;
		
			&-title {
				font-size: 18px;
				text-align: center;
				padding: 0 20px;
				font-weight: 400;
			}

			&-wrapper {
				margin: 25px 0 30px;
			}

			&-item {
				width: 140px;
				font-size: 14px;
				overflow: hidden;
				padding: 10px 11px;

				br {
					display: none;
				}

				&:after {
					background-size: contain;
				}

				&:nth-child(1):after {
					right: 6px;
					top: 32px;
					background-size: contain;
					width: 136px;
				}

				&:nth-child(2):after {
					right: 1px;
					top: 32px;
					background-size: contain;
					width: 147px;
				}

				&:nth-child(3):after {
					right: 5px;
					top: 24px;
					background-size: contain;
					width: 134px;
				}

				&:nth-child(4):after {
					right: -9px;
					top: 1px;
					background-size: contain;
					width: 158px;
				}

				&:nth-child(5):after {
					right: -17px;
					top: 5px;
					background-size: contain;
					width: 177px;
				}

				&:nth-child(6):after {
					right: -30px;
					top: 6px;
					width: 199px;
					background-size: contain
				}
			}

			&-btn.btn {
				width: initial;
				padding: 0 20px;

				&:after {
					content: none;
				}
			}

			&-card {
				font-size: 14px;
				color: #FFFFFF;
				display: flex;
				align-items: center;

				img {
					margin: 0 -50px -15px 0;
				}
			}
		}
	}
}

@media (max-width: 321px) {
	.test {
		&-block {
			width: 320px;
		}
	}
}