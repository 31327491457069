.inst {
	&__wrapper {
		height: 900px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.instagram {
	&_gallery {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	&-sidecar,
	&-video,
	&-image {
		width: 23%;
		margin-bottom: 27px;
		transition: transform 0.2s;
		
		img {
			height: 241px;
			width: 100%;
			object-fit: cover;
		}

		&:hover {
			transform: scale(1.1);
		}
	}
}

@media (max-width: 800px) {
	.inst {
		&__wrapper {
			height: initial;
			margin-top: 35px;
		}
	}

	.instagram {
		&-sidecar,
		&-video,
		&-image {
			width: 33%;
			margin-bottom: 0;
			
			img {
				height: 100px;
			}
		}
	}

	.page__inst {
		margin-top: -80px
	}
}