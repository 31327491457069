.header {
	&-fixed {
		background: radial-gradient(48.54% 57.75% at 58.87% 56.67%, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), radial-gradient(270.01% 138.53% at 49.84% 17.66%, rgba(196, 196, 196, 0.2) 0%, rgba(255, 255, 255, 0) 100%), #000000;
		color: #FFFFFF;
		position: fixed;
		top: 0;
		width: 100%;
		z-index: 100;
		display: none;
		height: 50px;

		.header-menu__item {
			color: #FFFFFF;
		}

		.header-call {
			&:before,
			&:after {
				content: none;
			}
		}
	}

	&-top {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&-logo {
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
	}

	&-menu {
		display: flex;
		width: 650px;
		justify-content: space-between;
		align-items: center;

		&__item {
			color: #5B5B5B;
			cursor: pointer;
			text-decoration: none;
			width: 150px;
			display: flex;
			align-items: center;
			justify-content: center;
			height: 30px;
			margin: 0 10px;

			&:hover {
				background: radial-gradient(50% 7.43% at 50% 100%, #fff 0%, rgba(255,255,255,0) 100%),radial-gradient(67.05% 156.76% at 50% -16.22%, rgba(255,255,255,0.7) 0%, rgba(255,255,255,0) 100%),linear-gradient(180deg, #FFDF6C 0%, rgba(255,255,255,0) 5.73%),linear-gradient(180deg, #FFCC16 0%, rgba(255,255,255,0) 100%),linear-gradient(180deg, rgba(92,72,0,0) 85.94%, rgba(255,247,174,0.142) 100%),#f90;
				border-radius: 10px;
				box-shadow: 0px 3px 0px #ECA843, 0px 4px 4px rgba(0,0,0,0.25);
				color: #5B5B5B;
				cursor: pointer;
				text-decoration: none;
			}
		}
	}

	&-call {
		cursor: pointer;
		text-align: center;
		position: relative;

		&__top {
			font-size: 12px;
			color: #0DB611;

			&.error {
				color: #ff0000;
			}
		}

		&__middle {
			font-weight: bold;
			font-size: 36px;
			position: relative;
			padding-left: 20px;
			color: #000000;
			text-decoration: none;
			display: block;
			width: 175px;
			margin: 0 auto;

			&:before {
				content: '';
				width: 59px;
				height: 58px;
				position: absolute;
				left: -21px;
				top: -7px;
				background: url('/images/phone-icon.png') no-repeat center left;
			}

			&-first {
				background: linear-gradient(0deg, #f88800 30%, #EAD600 68%, #fff 84%);
				text-decoration: none;
				position: relative;
				z-index: 1;
				-webkit-background-clip: text;
    			-webkit-text-fill-color: transparent;
			}
	
			&-second {
				position: absolute;
				top: 1px;
				left: 18px;
				right: 0;
			}
		}

		&__bottom {
			font-weight: 500;
			font-size: 12px;
		}

		&:before,
		&:after {
			content: "";
			width: 15px;
			height: 35px;
			border: 1px solid #202020;
			border-radius: 100% 0 0 100% / 50% 0 0 50%;
			position: absolute;
			left: -15px;
			top: 0;
			bottom: 0;
			margin: auto 0;
			border-right: 0;
		}
		&:after {
			right: -17px;
			left: inherit;
			border-right: 1px solid #202020;
			border-left: 0;
			border-radius: 0 100% 100% 0 / 0 50% 50% 0;
		}
	}

	&__middle {
		margin: 100px 0 160px;
	}

	&-middle {
		width: 590px;
	}

	&-subtitle {
		opacity: 0.6;
		font-weight: 600;
		font-size: 18px;
	}

	&-title {
		text-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1), 1px 1px 0px rgba(0, 0, 0, 0.3);
		font-weight: bold;
		font-size: 30px;
		width: 820px;

		&__highlight {
			color: #FFB800;
		}
	}

	&-list {
		display: flex;
		flex-wrap: wrap;

		&__item {
			width: 50%;
			display: flex;
			margin-bottom: 19px;
			align-items: center;

			&:before {
				content: '';
				flex: 0 0 auto;
				width: 16px;
				height: 16px;
				background: radial-gradient(50% 7.43% at 50% 100%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), radial-gradient(67.05% 156.76% at 50% -16.22%, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(180deg, #FFDF6C 0%, rgba(255, 255, 255, 0) 5.73%), linear-gradient(180deg, #FFCC16 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(180deg, rgba(92, 72, 0, 0) 85.94%, rgba(255, 247, 174, 0.142) 100%), #FF9900;
				box-shadow: 0px 2px 0px #ECA843, 0px 4px 4px rgba(0, 0, 0, 0.25);
				border-radius: 100px;
				margin-right: 12px;
			}

			&:nth-child(2n) {
				margin-left: -50px;
			}
		}
	}

	&__list {
		margin: 40px 0;
	}

	&-bottom {
		&-list {
			display: flex;
			justify-content: space-between;
			&__item {
				background: url('/images/header-bottom-list__item.png') no-repeat center;
				width: 212px;
				height: 136px;
				text-align: center;
				padding: 65px 20px 0;
			}
		}
	}

	&__btn {
		width: 486px;
	}
}

@media (max-width: 800px) {
	.header {
		padding: 15px 0 0;

		&-top {
			flex-direction: column;
		}

		&-call {
			margin-top: 28px;
		}

		&-menu {
			display: none;
		}
		
		&-title {
			font-size: 28px;
			width: 100%;

			br {
				display: none;
			}
		}

		&-subtitle {
			font-weight: 500;
		}

		&__middle {
			margin: 50px 0 70px;
		}

		&-middle {
			width: initial;
		}

		&-list {
			&__item {
				font-size: 14px;
				align-items: start;

				br {
					display: none;
				}

				&:nth-child(2n) {
					margin-left: 0;
				}

				&:nth-child(1) {
					order: 2;
				}

				&:nth-child(5) {
					order: 1;
				}

				&:nth-child(3) {
					order: 4;
				}

				&:nth-child(4) {
					order: 5;
					margin-top: -40px;
				}

				&:nth-child(6) {
					order: 3;
					margin-top: -20px;
				}
				
				&:nth-child(2) {
					order: 7;
				}

				&:nth-child(7) {
					order: 6;

					&:before {
						content: none;
					}
				}
			}
		}

		&__list {
			margin: 50px 0 250px;
		}

		&__btn {
			width: initial;
		}

		&-bottom {
			&-list {
				flex-wrap: wrap;
				&__item {
					background: url(/images/header-bottom-list__item-m.png) no-repeat center;
					width: 160px;
					height: 100px;
					font-weight: 600;
					font-size: 12px;
					opacity: 0.8;
					padding: 57px 15px 0;
				}
			}
		}
	}
}