.customers {
	&__wrapper {
		display: flex;
		flex-wrap: wrap;
		margin: 113px 0 20px;
	}

	&-item {
		width: 245px;
		height: 118px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: 55px;

		&:nth-child(4) {
			margin-right: 0;
		}
	}

	&__show-all {
		font-size: 20px;
		text-align: center;
		text-decoration-line: underline;
		opacity: 0.8;
		cursor: pointer;
	}
}

.page__customers {
	margin-top: -340px;
}

.bg-customers_7 {
    width: 231px; height: 58px;
    background: url('/images/customers-sprites.png') -223px -251px;
}

.bg-customers_8 {
    width: 157px; height: 115px;
    background: url('/images/customers-sprites.png') -275px -108px;
}

.bg-customers_9 {
    width: 228px; height: 78px;
    background: url('/images/customers-sprites.png') -275px -10px;
}

.bg-customers_10 {
    width: 203px; height: 52px;
    background: url('/images/customers-sprites.png') -10px -441px;
}

.bg-customers_11 {
    width: 245px; height: 83px;
    background: url('/images/customers-sprites.png') -10px -10px;
}

.bg-customers_1 {
    width: 180px; height: 52px;
    background: url('/images/customers-sprites.png') -233px -441px;
}

.bg-customers_2 {
    width: 193px; height: 79px;
    background: url('/images/customers-sprites.png') -10px -251px;
}

.bg-customers_3 {
    width: 224px; height: 51px;
    background: url('/images/customers-sprites.png') -216px -350px;
}

.bg-customers_4 {
    width: 175px; height: 118px;
    background: url('/images/customers-sprites.png') -10px -113px;
}

.bg-customers_5 {
    width: 146px; height: 61px;
    background: url('/images/customers-sprites.png') -523px -10px;
}

.bg-customers_6 {
    width: 186px; height: 71px;
    background: url('/images/customers-sprites.png') -10px -350px;
}

@media (max-width: 800px) {
	.customers {
		&__wrapper {
			margin: 35px 0 20px;
			justify-content: space-between;
		}

		&-item {
			width: 103px;
			height: 60px;
			margin-right: 0;

			&:nth-child(n+10) {
				display: none;
			}
		}
	}

	.page__customers {
		margin-top: 0;
	}

	.bg-customers_5 {
		width: 98px; height: 24px;
		background: url('/images/customers-sprites-m.png') -231px -118px;
	}

	.bg-customers_9 {
		width: 89px; height: 60px;
		background: url('/images/customers-sprites-m.png') -10px -10px;
	}

	.bg-customers_8 {
		width: 61px; height: 45px;
		background: url('/images/customers-sprites-m.png') -231px -53px;
	}

	.bg-customers_1 {
		width: 83px; height: 24px;
		background: url('/images/customers-sprites-m.png') -120px -90px;
	}

	.bg-customers_4 {
		width: 96px; height: 30px;
		background: url('/images/customers-sprites-m.png') -10px -147px;
	}

	.bg-customers_7 {
		width: 92px; height: 38px;
		background: url('/images/customers-sprites-m.png') -119px -10px;
	}

	.bg-customers_2 {
		width: 90px; height: 37px;
		background: url('/images/customers-sprites-m.png') -10px -90px;
	}

	.bg-customers_3 {
		width: 103px; height: 23px;
		background: url('/images/customers-sprites-m.png') -231px -10px;
	}

	.bg-customers_6 {
		width: 97px; height: 24px;
		background: url('/images/customers-sprites-m.png') -10px -197px;
	}
}