.uslugi-list {
	&-line {
		background: linear-gradient(180deg, rgba(255, 204, 22, 0.5) 0%, rgba(255, 204, 22, 0) 100%), linear-gradient(0deg, rgba(255, 198, 96, 0.8), rgba(255, 198, 96, 0.8)), #FF9900;
		box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
		border-radius: 10px;
		height: 50px;
		margin: 10px 0 30px;
	}

	&__header {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-top: 86px;

		&-item {
			background: radial-gradient(270.01% 138.53% at 49.84% 17.66%, rgba(196, 196, 196, 0.3) 0%, rgba(255, 255, 255, 0) 100%), #FDFDFD;
			box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
			border-radius: 10px;
			padding: 22px 37px;
			height: 132px;
			width: 390px;
			margin-bottom: 15px;
			cursor: pointer;
			position: relative;
			color: #202020;
			text-decoration: none;

			&._active,
			&:hover {
				height: 146px;
				background: url('/images/uslugi-list__header-item--hover.png') no-repeat center;
				box-shadow: none;
				margin-top: -14px;
				padding: 36px 37px 22px;
			}

			&:nth-child(n+4)._active,
			&:nth-child(n+4):hover {
				background: url('/images/uslugi-list__header-item--hover-bottom.png') no-repeat center;
				margin-top: 0;
				margin-bottom: -10px;
				padding: 22px 37px;
			}

			&-title {
				font-weight: 500;
				font-size: 22px;
				margin-bottom: 10px;
			}

			&-desc {
				font-weight: 300;
				font-size: 13px;
			}

			&:after {
				content: '';
				position: absolute;
				background-position: center;
				background-repeat: no-repeat;
				right: 0;
				bottom: 0;
			}

			&:nth-child(1) {
				.uslugi-list__header-item-desc {
					padding-right: 100px;
				}

				&:after {
					width: 164px;
					height: 146px;
					background-image: url('/images/uslugi-list__header-item-1.png');
				}
			}

			
			&:nth-child(2) {
				.uslugi-list__header-item-desc {
					padding-right: 100px;
				}

				&:after {
					width: 192px;
					height: 151px;
					right: -10px;
					bottom: -5px;
					background-image: url('/images/uslugi-list__header-item-2.png');
				}
			}

			&:nth-child(3) {
				.uslugi-list__header-item-title {
					padding-right: 100px;
				}

				.uslugi-list__header-item-desc {
					padding-right: 100px;
				}

				&:after {
					width: 201px;
					height: 151px;
					bottom: -19px;
					border-top-right-radius: 10px;
					background-image: url('/images/uslugi-list__header-item-3.png');
				}
			}

			&:nth-child(5) {
				.uslugi-list__header-item-desc {
					padding-right: 135px;
				}

				&:after {
					width: 257px;
					height: 151px;
					bottom: initial;
					top: -19px;
					background-image: url('/images/uslugi-list__header-item-5.png');
				}
			}

			&:nth-child(6) {
				.uslugi-list__header-item-desc {
					padding-right: 135px;
				}

				&:after {
					width: 219px;
					height: 151px;
					bottom: initial;
					top: -10px;
					background-image: url('/images/uslugi-list__header-item-6.png');
				}
			}
		}
	}

	&__main {
		display: flex;
		flex-wrap: wrap;

		&-item {
			background: radial-gradient(270.01% 138.53% at 49.84% 17.66%, rgba(196, 196, 196, 0.3) 0%, rgba(255, 255, 255, 0) 100%), #FDFDFD;
			border-radius: 5px;
			padding: 0 17px 20px 20px;
			height: 293px;

			&-img {
				display: flex;
				height: 150px;
				justify-content: center;
				align-items: center;
			}

			&-title {
				font-weight: 300;
				font-size: 14px;
				margin: 0 0 20px;

				span {
					font-size: 11px;
				}
			}

			&-wrapper {
				background: #FFFFFF;
				box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
				border-radius: 5px;
				padding: 7px;
				width: 280px;
				position: relative;
				margin: 0 26px 30px 0;

				&:nth-child(4n) {
					margin-right: 0;
				}

				&:hover {
					.uslugi-list__main-item {
						background: transparent;
					}

					.uslugi-list__main-btn {
						display: flex;
					}
				}
			}

			&-prop {
				font-weight: 300;
				font-size: 12px;
				display: flex;
				justify-content: space-between;
			}
		}

		&-btn.btn {
			position: absolute;
			display: none;
			bottom: -3px;
			left: 0;
			right: 0;
			margin: 0 auto;
			font-weight: 600;
			font-size: 14px;
			width: 265px;
			height: 35px;
			align-items: center;
			justify-content: center;
			text-decoration: none;
			z-index: 10;
		}

		&._sneg {
			.uslugi-list__main-item-wrapper {
				width: 587px;
				margin: 0 13px 18px 0;
			}

			.uslugi-list__main-item {
				height: 200px;
				padding: 17px 17px 20px 20px;
			}

			.uslugi-list__main-item-title {
				font-weight: 500;
				font-size: 22px;
				width: 231px;
			}

			.uslugi-list__main-item-img {
				position: absolute;
				right: -32px;
				top: 29px;

				&._right {
					right: -70px;
				}
			}

			.uslugi-list__main-btn {
				margin: initial;
				margin-left: 25px;
			}
		}
	}

	&._mixture {
		background: url('/images/mixture-bg.png') no-repeat center top 432px;

		.uslugi-list-line {
			display: none;
		}
	}

	&._brigade {
		background: url('/images/brigade-bg.png') no-repeat center top 432px;
		
		.description__icon {
			background: url('/images/brigade-desc-icon.png') no-repeat center;
			right: -75px;
			bottom: 0;
		}

		.uslugi-list-line {
			display: none;
		}
	}

	.description {
		height: 976px;
		background: url('/images/description-bg.png') no-repeat center;
		padding: 45px 0 0;
		position: relative;
		margin-top: 90px;
	
		&__swiper {
			height: 514px;
			width: 629px;
	
			.swiper-slide {
				background-size: cover;
				background-position: center;
				cursor: pointer;
			}
	
			&-top {
				height: 80%;
				width: 100%;
				border-radius: 3px;
				border-top-left-radius: 10px;
			}
	
			&-thumbs {
				height: 20%;
				margin-top: 10px;
				border-radius: 3px;
	
				.swiper-slide {
					width: 33%;
					height: 100%;
				}
			}
		}
	
		&__top {
			display: flex;
		}
	
		&__main {
			padding: 52px 69px 0 56px;
	
			&-title {
				font-weight: bold;
				font-size: 30px;
				margin-bottom: 25px;
			}
	
			&-desc {
				p {
					margin: 15px 0;
				}
			}
		}
	
		&__footer {
			display: flex;
			padding: 67px 0 0 67px;
	
			&-item {
				width: 413px;
				margin-right: 50px;
	
				&-title {
					font-weight: bold;
					font-size: 20px;
					margin-bottom: 20px;
				}
			}
		}
	
		&__icon {
			width: 495px;
			height: 435px;
			position: absolute;
			right: -100px;
			bottom: -20px;
			background: url('/images/mixture-desc-icon.png') no-repeat center;
		}
	}

	.vigoda {
		background: url('/images/vigoda-bg.png') no-repeat center;
		height: 536px;
		position: relative;
		padding: 110px 0 0 100px;
		width: 100%;
	
		&__title {
			font-weight: bold;
			font-size: 30px;
		}
	
		&__desc {
			margin-top: 30px;
			width: 711px;
		}
	
		&__terminal {
			width: 467px;
			height: 203px;
			background: url('/images/vigoda-terminal.png') no-repeat center;
			padding: 82px 0 0 188px;
			position: absolute;
			bottom: -70px;
			left: 180px;
	
	
			&-title {
				font-weight: bold;
				margin-bottom: 8px;
			}
		}
	
		&__icon {
			width: 651px;
			height: 564px;
			position: absolute;
			right: -110px;
			bottom: -15px;
			background: url('/images/tehnika-vigoda-icon.png') no-repeat center;
		}
	}

	.form {
		width: 528px;
		height: 625px;
		background: url('/images/form-bg.png') no-repeat center;
		text-align: center;
		margin: 100px 0 0 723px;
		padding: 110px 70px 0 70px;
	
		&__title {
			font-weight: bold;
			font-size: 30px;
			margin-bottom: 30px;
		}
	
		&__phone {
			font-weight: bold;
			font-size: 40px;
			margin: 5px 0 30px;
			color: #202020;
			text-decoration: none;
		}
	
		&__wrapper {
			padding: 0 30px;
			text-align: left;
			margin: 10px 0 0;
		}
	
		&__btn {
			margin: 15px 0;
		}
	
		&__desc {
			padding: 0 30px;
		}
	}
}

.page__uslugi-list {
	margin-top: 150px;
	position: relative;
	z-index: 2;
}

@media (max-width: 800px) {
	.uslugi-list {
		&__header {
			margin-top: 60px;

			&-item {
				overflow: hidden;

				&-title {
					font-size: 18px;
				}

				&-desc {
					font-size: 12px;
				}

				&:after {
					background-size: contain;
				}

				&:nth-child(n+4)._active,
				&:nth-child(n+4):hover {
					background: url('/images/uslugi-list__header-item--hover.png') no-repeat center;
					margin-top: -14px;
					margin-bottom: 15px;
					padding: 36px 37px 22px;
				}

				&:nth-child(1):after {
					bottom: -28px;
				}

				&:nth-child(2):after {
					width: 165px;
					right: -28px;
					bottom: -30px;
				}

				&:nth-child(3):after {
					right: -28px;
					bottom: -19px;
				}

				&:nth-child(5):after {
					right: -53px;
					top: initial;
					bottom: -15px;
				}

				&:nth-child(6):after {
					right: -10px;
					top: initial;
					width: 191px;
					bottom: -6px;
				}
			}
		}

		&-line {
			height: 17px;
			margin: 25px 0 17px;
		}

		&__main {
			&-item {
				height: 138px;
				padding: 11px 107px 11px 19px;
				display: flex;
				flex-direction: column;
				justify-content: space-between;

				&-title {
					margin: 0;
				}

				&-wrapper {
					width: 100%;
					margin: 0 0 20px 0;
				}

				&-img {
					position: absolute;
					right: 0;
					height: 80px;
					width: 119px;

					img {
						object-fit: contain;
						height: 100%;
					}
				}
			}

			&-btn.btn {
				display: flex;
				bottom: 12px;
				width: 119px;
				right: -10px;
				left: initial;
			}

			&._sneg {
				.uslugi-list__main-item {
					height: 154px;
				}

				.uslugi-list__main-btn {
					left: 0;
					bottom: 20px;
				}

				.uslugi-list__main-item-title {
					width: 155px;
					font-size: 16px;
				}

				.uslugi-list__main-item-img {
					right: 17px;
					top: -4px;
					height: 146px;
					width: 150px;

					&._right {
						right: -16px;
						height: 203px;
						width: 150px;
						top: -4px;
					}
				}
			}
		}

		.description {
			height: 1332px;
			padding: 45px 0 0;
			background: url('/images/description-bg-m.png') no-repeat center;
		
			&__top {
				display: block;
			}
			
			&__swiper {
				width: 320px;
				height: 262px;
	
				&-top .swiper-slide {
					border-top-left-radius: 10px;
					border-top-right-radius: 10px;
				}
			}
	
			&__icon {
				width: 100%;
			}
	
			&__main {
				padding: 0;
	
				&-title {
					font-size: 18px;
					text-align: center;
					margin-top: 40px;
				}
	
				&-desc {
					padding: 0 18px;
					font-size: 14px;
				}
			}
	
			&__footer {
				padding: 0 18px;
				flex-direction: column;
	
				&-item {
					width: 100% !important;
					margin-right: 0;
					margin-top: 40px;
	
					&-title {
						font-size: 14px;
					}
	
					&-desc {
						font-size: 14px;
					}
				}
			}
	
			&__icon {
				max-width: 392px;
				width: 100%;
				height: 414px;
				right: 0;
				bottom: -75px;
				left: 0;
				background-size: 143%;
			}
		}

		.vigoda {
			background: url(/images/description-bg-m.png) no-repeat top;
			position: relative;
			padding: 100px 35px 250px;
			height: initial;
	
			&__title {
				font-size: 24px;
			}
	
			&__desc {
				width: 100%;
				font-size: 14px;
			}
	
			&__icon {
				max-width: 480px;
				background-size: 143%;
				bottom: -200px;
				width: 100%;
				right: 0;
			}
	
			&__terminal {
				width: 320px;
				height: 203px;
				background-size: contain;
				left: 0;
				bottom: -320px;
				padding: 75px 0 0 110px;
				background: url(/images/vigoda-terminal-m.png) no-repeat center;
	
				&-title {
					font-size: 14px;
				}
	
				&-desc {
					font-size: 14px;
				}
			}
		}

		.form {
			width: 320px;
			height: 598px;
			background: url('/images/form-bg-m.png') no-repeat center;
			margin: 490px 0 0 0;
			padding: 110px 0px 0;
	
			&__title {
				font-size: 25px;
			}
	
			&__desc {
				font-size: 14px;
			}
	
			&__phone {
				font-size: 40px;
			}
	
			&-desc {
				font-size: 12px;
			}
		}

		&._mixture {
			background: url(/images/mixture-bg-m.png) no-repeat center top 1032px;
		}

		&._brigade {
			background: url(/images/brigade-bg-m.png) no-repeat center top 1032px;
		
			.description__icon {
				bottom: -70px;
				background-size: cover;
			}
		}
	}

	.page__uslugi-list {
		margin-top: 88px;
	}
}