.stages {
	background: url(/images/stages-back.png) no-repeat top -80px center;
	height: 1506px;
	padding-top: 205px;

	&__wrapper {
		display: flex;
		justify-content: space-between;
		margin-top: 50px;
	}

	&__item {
		background: radial-gradient(458.25% 145.53% at 47.86% 10.66%, rgba(196, 196, 196, 0.3) 0%, rgba(255, 255, 255, 0) 100%), #FDFDFD;
		box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
		border-radius: 10px;
		padding: 70px 24px 0 70px;
		width: 306px;
		height: 620px;
		position: relative;
		flex: 0 0 auto;

		&-title {
			font-weight: bold;
			font-size: 26px;
			line-height: 26px;
		}

		&-subtitle {
			font-size: 19px;
			margin-bottom: 22px;
		}

		&-desc {
			margin-bottom: 43px;
		}

		&-list {
			&-item {
				display: flex;

				&:before {
					content: "";
					background: radial-gradient(50% 7.43% at 50% 100%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), radial-gradient(67.05% 156.76% at 50% -16.22%, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(180deg, #FFDF6C 0%, rgba(255, 255, 255, 0) 5.73%), linear-gradient(180deg, #FFCC16 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(180deg, rgba(92, 72, 0, 0) 85.94%, rgba(255, 247, 174, 0.142) 100%), #FF9900;
					box-shadow: 0px 1px 0px rgba(236, 168, 67, 0.5), 0px 4px 4px rgba(0, 0, 0, 0.25);
					border-radius: 100px;
					width: 8px;
					height: 8px;
					margin: 4px 14px 0 0;
					flex: 0 0 auto;
				}
			}
		}

		&-img {
			position: absolute;
			bottom: 0;
			display: flex;
			left: 0;
			right: 0;
			margin: auto;
			justify-content: center;
		}

		&:before {
			content: attr(number);
			position: absolute;
			font-weight: 900;
			font-size: 80px;
			color: #CFCFCF;
			right: 250px;
			top: 38px;
		}
	}

	&-block {
		display: flex;
		flex-direction: column;
		width: 549px;
		flex: 0 0 auto;
		justify-content: space-between;

		&__item {
			height: 300px;
			width: 100%;
			padding-left: 220px;

			.stages__item-img {
				right: initial;
			}

			.stages__item-desc {
				margin-bottom: 20px;
			}

			&:before {
				right: 342px;
			}
		}
	}
}

@media (max-width: 800px) {
	.stages {
		margin-top: 90px;
		padding-top: 0;
		background: url(/images/stages-back-m.png) no-repeat top;
		height: 1518px;

		&-block {
			width: 100%;

			.stages__item-img {
				right: -25px;
			}
		}

		&__wrapper {
			flex-direction: column;
		}

		&__item {
			padding: 38px 11px 0 70px;
			width: 100%;
			height: 310px;
			margin-bottom: 25px;

			&:before {
				top: 8px;
				right: 265px;
			}

			&[number="2"] {
				.stages__item-img {
					left: -170px;
					height: 230px;
				}
			}

			&[number="3"] {
				.stages__item-img {
					bottom: -5px;
				}
			}

			&[number="4"] {
				.stages__item-img {
					left: -150px;
				}
			}

			&-img {
				left: -200px;
				height: 200px;
				
				img {
					object-fit: contain;
				}
			}

			&-title {
				font-size: 22px;
				line-height: 27px;
			}

			&-subtitle {
				font-size: 18px;
				margin-bottom: 20px;
			}

			&-desc {
				font-size: 14px;
				margin: 0 0 25px 52px;
			}

			&-list {
				margin-left: 75px;

				&-item {
					font-size: 14px;
				}
			}
		}
	}
}