.whatsapp {
	height: 1425px;
	background: url('/images/whatsapp-bg.png') no-repeat top center;
	padding-top: 335px;
	margin-bottom: -50px;
	margin-top: -150px;
	position: relative;
	z-index: 1;
	
	&__header {
		width: 686px;
		margin: 0 auto;
	}

	&__subtitle {
		font-size: 18px;
		margin-bottom: 10px;
	}

	&__title {
		font-weight: bold;
		font-size: 36px;
		line-height: 35px;
	}
	
	&__yellow-title {
		font-weight: 500;
		font-size: 30px;
		color: #FFB930;
		margin: 2px 0 0 55px;
	}

	&__desc {
		font-style: italic;
		font-size: 18px;
		opacity: 0.8;
		margin: 45px 0 0 198px;
	}

	&__form {
		width: 396px;
		margin: -41px 47px 0 0;
		padding: 0 45px;
		color: #EEEEEE;

		&-wrapper {
			display: flex;
			justify-content: flex-end;
		}

		&-title {
			font-weight: bold;
			font-size: 30px;
			text-align: center;
			color: #FFFFFF;
		}

		&-subtitle {
			font-size: 16px;
			text-align: center;
			color: #EEEEEE;
			margin-top: 10px;
		}

		&-desc {
			margin-top: 20px;
		}

		&-group {
			margin: 50px 0 15px;
		}
	}
}

#whatsapp-block {
	position: absolute;
	top: 200px;
}

@media (max-width: 800px) {
	.whatsapp {
		background: url('/images/whatsapp-bg-m.png') no-repeat center;
	
		&__header {
			width: initial;
			padding: 60px 15px 25px 25px;
		}

		&__subtitle {
			font-size: 14px;
		}

		&__title {
			font-weight: 500;
			font-size: 20px;
			line-height: 22px;
		}

		&__yellow-title {
			font-size: 18px;
			font-weight: 600;
			margin: 10px 0 0 62px;
		}

		&__desc {
			font-size: 14px;
			margin: 16px 0 0 90px;
		}

		&__form {
			width: initial;
			margin: 250px -20px 0;

			&-title {
				font-size: 24px;
			}

			&-group {
				margin: 30px 0 15px;
			}
		}
	}
}