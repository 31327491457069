.bg-1 {
	background: url('/images/main4.webp') no-repeat top center;
	height: 2607px;
}

.bg-2 {
	background: url('/images/bg_2.jpg') no-repeat top center;
	height: 899px;
}

.bg-3 {
	background: url('/images/bg_3.png') no-repeat top;
}

@media (max-width: 800px) {
	.bg-1 {
		background: url('/images/bg_1-m.jpg') no-repeat top center;
		height: 4108px;
	}

	.bg-2 {
		background: radial-gradient(48.54% 57.75% at 58.87% 56.67%, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), radial-gradient(270.01% 138.53% at 49.84% 17.66%, rgba(196, 196, 196, 0.2) 0%, rgba(255, 255, 255, 0) 100%), #000000;
		height: 1058px;
	}

	.bg-3 {
		background: none;
	}
}