.uslugi {
	&__title {
		margin-bottom: 40px;

		br {
			display: block;
		}
	}

	&__subtitle {
		font-weight: 400;
    	font-size: 30px;
	}

	&-block {
		background: radial-gradient(270.01% 138.53% at 49.84% 17.66%, rgba(196, 196, 196, 0.2) 0%, rgba(255, 255, 255, 0) 100%), #FFFFFF;
		box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
		border-radius: 10px;
		padding: 18px;
		display: flex;
		justify-content: space-between;

		&-item {
			position: relative;

			&:hover {
				.uslugi-block-item-hover {
					opacity: 1;
				}
			}

			img {
				border-radius: 10px;
			}

			&-hover {
				background: linear-gradient(180deg, rgba(255, 213, 89, 0.8) 0%, rgba(255, 216, 95, 0) 100%), rgba(255, 198, 96, 0.9);
				box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.2);
				padding: 40px 26px 36px 30px;
				border-radius: 10px;
				position: absolute;
				top: 0;
				width: 100%;
				opacity: 0;
				transition: opacity 0.2s;
			}
		}

		&-right {
			width: 724px;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;

			.uslugi-block-text {
				font-size: 14px;
				margin: 14px 0 24px;
			}

			.uslugi-block-item-hover {
				padding: 29px 26px 36px 30px;
			}

			img {
				border-radius: 10px;
			}
		}

		&-title {
			font-weight: 500;
			font-size: 20px;
		}

		&-text {
			margin: 24px 0;
		}
	}
}

.page__uslugi {
	margin: 134px 0 220px;

	&-container {
		width: 1236px;
    	margin: 0 auto;
	}
}

@media (max-width: 800px) {
	.uslugi {
		&-block {
			padding: 10px;
			flex-direction: column;

			&-right {
				flex-direction: column;
				width: initial;
			}

			&-title {
				font-weight: 600;
				font-size: 18px;
			}

			&-text {
				font-size: 11px !important;
				margin: 10px 0 15px;
			}

			&-item {
				margin-bottom: 8px;

				img {
					width: 300px !important;
					height: 255px !important;
					object-fit: cover;
				}

				&-hover {
					height: 255px !important;
					opacity: 1;
					padding: 20px 20px 0 25px !important;
					background: linear-gradient(180deg, #FFC660 35.42%, rgba(255, 216, 95, 0) 100%);
				}

				&:last-child {
					margin-bottom: 0;
				}
			}
		}

		.uslugi__title {
			font-size: 18px;
		}

		&__subtitle {
			font-size: 16px;
		}
	}

	.page__uslugi {
		margin: 87px 0 160px;
	
		&-container {
			width: 320px;
		}
	}
}