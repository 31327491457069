.footer {
	padding-bottom: 10px;

	&__flex {
		display: flex;
		justify-content: space-between;
		margin-top: 10px;
		align-items: center;
	}

	&__text {
		font-weight: 500;
		font-size: 14px;
		color: #202020;
		opacity: 0.6;
		text-decoration: none;
	}

	&-menu {
		width: 548px;
		display: flex;
		justify-content: space-between;

		&__item {
			font-weight: 600;
			color: #5B5B5B;
			text-decoration: none;
			cursor: pointer;
		}
	}

	&__phone-block {
		text-align: right;

		&-week {
			font-weight: 500;
			font-size: 11px;
		}

		&-number {
			font-weight: 600;
			font-size: 30px;
			color: #202020;
			text-decoration: none;
			position: relative;
			display: block;
		}
	}
}

@media (max-width: 800px) {
	.footer {
		&-menu {
			width: 100%;
			flex-wrap: wrap;

			&__item {
				font-weight: 500;
				text-align: center;
				width: 150px;
				margin-bottom: 10px;
			}

			&__img {
				text-align: center;
				margin-bottom: 8px;
			}
		}

		&__phone {
			&-block {
				text-align: center;
				margin: 35px 0 20px;

				&-number {
					.header-call__middle-second {
						left: 0;
					}
				}
			}
		}

		&__text {
			display: block;
			text-align: center;
		}
	}
}