.snow-quiz-modal {
    .test-wrapper {
        -webkit-box-shadow: 0 0 30px rgba(152, 152, 152, 0.1);
        box-shadow: 0 0 30px rgba(152, 152, 152, 0.1);
        border-radius: 10px;
        background-color: #ffffff;
        overflow: hidden;
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .test-wrapper.predfinals {
        overflow: unset;
    }

    .test__main {
        width: 77%;
        padding: 58px 43px 34px 79px;
		position: relative;
		height: 500px;
    }

    .test__main.final {
        width: 100%;
    }

    .left-block {
        width: 278px;
        height: 500px;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        padding: 20px 38px;
        background: url("/images/stest-bg.jpg") no-repeat center;
        background-size: cover;
        text-align: center;
        border-radius: 10px 0 0 10px;
    }

    .left-block-subtitle {
        color: #414141;
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 12px;
	}

	.predfinal .test-text-wrap {
		text-align: left;
	}
	
	.predfinal .left-block-title {
		font-size: 14px;
	}

    .left-block-title {
		color: #2E2E2E;
		font-weight: 500;
		font-size: 20px;
		text-transform: uppercase;
		font-weight: bold;
    }

    .left-block-title .trigger {
        color: #FFCC16;
        font-style: italic;
        font-weight: 700;
    }

    .left-block.no-show {
        overflow: hidden;
        width: 0;
        padding: 0;
    }

    .progress-bar {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        flex-direction: column;
        margin: 0 auto;
        margin-bottom: 15px;
    }

    .progress-bar__title {
		color: #2E2E2E;
		opacity: 0.6;
		font-weight: 600;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        margin-bottom: 16px;
    }

    .progress-bar__line {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 19%;
        display: flex;
        align-items: center;
        justify-content: center;
		box-shadow: inset 0 0 20px rgba(233, 227, 49, 0.2);
        background-color: #FFEB3B;
		background-image: linear-gradient(to right, rgba(220, 222, 88, 0.8) 0%, rgba(88, 222, 92, 0) 100%), repeating-linear-gradient(-55deg, #f4d532, transparent 6px, #f4d532 5px, #f4d532 5px);
        animation: progres 2.8s linear infinite;
        transition: width 0.4s;
    }

    .progress-bar__line-wrap {
        height: 4px;
        box-shadow: 0 0 30px rgba(183, 193, 199, 0.1);
        border-radius: 5px;
        background-color: #e7e7e7;
        width: 100%;
        overflow: hidden;
        position: relative;
    }

    @-webkit-keyframes progres {
        from {
            background-position: 0 0, 0 0;
        }

        to {
            background-position: 0 0, 60px 30px;
        }
    }

    @keyframes progres {
        from {
            background-position: 0 0, 0 0;
        }

        to {
            background-position: 0 0, 60px 30px;
        }
    }

    .checkbox-psevdo {
        width: 24px;
        height: 24px;
        border-radius: 3px;
        border: 1px solid #a8a3a1;
        background-color: #ffffff;
        padding: 3.8px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 13.3px;
        flex-shrink: 0;
    }

    .checkbox-psevdo-active {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-transform: scale(0);
        -ms-transform: scale(0);
        transform: scale(0);
        -webkit-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
    }

    .checkbox-psevdo-active img {
        width: 140%;
        max-width: 140%;
        -ms-flex-item-align: center;
        align-self: center;
    }

    .checkbox-inp:checked ~ .radio-label {
        color: #2a2a2a;
    }

    .checkbox-inp:checked ~ label .checkbox-psevdo-active {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
    }

    .radio-wrap {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        position: relative;
        margin-top: 2vh;
        margin-bottom: 3vh;
        width: 100%;
        font-weight: 500;
    }

    .radio-item {
        width: 45%;
        text-align: left;
    }

    .radio-label {
        color: #000000;
        display: inline-block;
        position: relative;
        -webkit-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        cursor: pointer;
        text-align: center;
        justify-content: center;
		margin-top: 9.5px;
		font-size: 15px;
    }

    .radio-psevdo {
        border: 1px solid #a8a3a1;
        background-color: #ffffff;
        width: 22.8px;
        height: 22.8px;
        border-radius: 100%;
        -webkit-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        margin-right: 11.4px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-flex: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
    }

    .radio-psevdo-active {
        width: 12.16px;
        height: 12.16px;
        background-color: #FFCC16;
        border-radius: 100%;
        opacity: 0;
        -webkit-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
    }

    .radio-block {
        width: 100%;
    }

    .radio-btn {
        display: none;
    }

    .radio-btn:checked ~ .radio-label {
        color: #2a2a2a;
    }

    .radio-btn:checked ~ label .radio-psevdo-active {
        opacity: 1;
    }

    .test-item-block {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .test-item__title {
        color: #212121;
        font-size: 22.8px;
        font-weight: 700;
        margin-bottom: 32.3px;
    }

    .test-item__title span {
        color: #ffd535;
    }

    .img-label {
        height: 121.6px;
        width: 100%;
        display: inline-block;
        border-radius: 10px;
        cursor: pointer;
    }

    .test_elem {
        -webkit-transform: all 0.4s;
        -ms-transform: all 0.4s;
        transform: all 0.4s;
        border-radius: 4px;
        width: 17.6%;
        margin: 0 1.5% 28.5px 1.5%;
        display: block;
        position: relative;
        cursor: pointer;
        -webkit-transition: all 0.4s;
        -o-transition: all 0.4s;
		transition: all 0.4s;
		
		&:first-of-type {
			margin-left: 0;
		}

		&:last-of-type {
			margin-right: 0;
		}
    }

    .test_elem-t2 {
        padding: 0;
        border-radius: 10px;
        text-align: center;
        display: block;
    }

    .qw1-bg {
        width: 146px;
        height: 186px;
        background: url("/images/s1-1.jpg") no-repeat top;
        border: 1px solid #c4c4c4;
        border-radius: 5px;
    }

    label[for="sqw1-2"] .qw1-bg {
        background-image: url("/images/s1-2.jpg");
    }

    label[for="sqw1-3"] .qw1-bg {
        background-image: url("/images/s1-3.jpg");
    }

    label[for="sqw1-4"] .qw1-bg {
        background-image: url("/images/s1-4.jpg");
	}
	
	label[for="sqw1-5"] .qw1-bg {
        background-image: url("/images/s1-5.jpg");
    }

    label[for="sqw2-1"] .qw1-bg {
        background-image: url("/images/s2-1.jpg");
    }

    .test__main.qw3 {
        background: url("/images/s3-bg.jpg") no-repeat right top 50px;
	}
	
	.test__main.finals {
        background: url("/images/spred-bg.jpg") no-repeat right bottom;
    }

    label[for="sqw2-2"] .qw1-bg {
        background-image: url("/images/s2-2.jpg");
    }

    label[for="sqw2-3"] .qw1-bg {
        background-image: url("/images/s2-3.jpg");
    }

    label[for="sqw2-4"] .qw1-bg {
        background-image: url("/images/s2-4.jpg");
    }

    label[for="sqw6-1"] .qw5-bg {
        background-image: url("/images/s6-1.jpg");
    }

    label[for="sqw6-2"] .qw5-bg {
        background-image: url("/images/s6-2.jpg");
    }

    label[for="sqw6-3"] .qw5-bg {
        background-image: url("/images/s6-3.jpg");
    }

    .test-text-wrap {
        width: 100%;
        text-align: center;
        margin-bottom: 23px;
    }

    .test__wrap {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }

    .test__qwiz {
        width: 75%;
    }

    .test__qwiz.active {
        width: 100%;
    }

    .test__gifts {
        -ms-flex-item-align: start;
        align-self: flex-start;
        text-align: center;
        width: 23%;
        padding: 38px;
        -webkit-box-shadow: inset 0 -2px 3px rgba(255, 255, 255, 0.16);
        box-shadow: inset 0 -2px 3px rgba(255, 255, 255, 0.16);
        border-radius: 4px;
        background-color: #ffd535;
        background-image: -webkit-gradient(
            linear,
            left bottom,
            left top,
            from(rgba(255, 255, 255, 0)),
            to(rgba(255, 255, 255, 0.4))
        );
        background-image: -webkit-linear-gradient(
            bottom,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.4) 100%
        );
        background-image: -o-linear-gradient(
            bottom,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.4) 100%
        );
        background-image: linear-gradient(
            to top,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.4) 100%
        );
	}
	
	.ui-state-default, .ui-widget-content .ui-state-default {
		border: 1px solid #c5c5c5;
		background: #f6f6f6;
		font-weight: normal;
		color: #454545;
	}

	.ui-slider-horizontal .ui-slider-handle {
		top: -.3em;
		margin-left: -.6em;
	}

	.ui-slider .ui-slider-handle {
		position: absolute;
		z-index: 2;
		width: 1.2em;
		height: 1.2em;
		cursor: default;
		-ms-touch-action: none;
		touch-action: none;
	}

	.ui-slider-horizontal .ui-slider-range {
		top: 0;
		height: 4px;
	}

	.ui-corner-all, .ui-corner-bottom, .ui-corner-right, .ui-corner-br {
		border-bottom-right-radius: 3px;
	}

	.ui-slider .ui-slider-range {
		position: absolute;
		z-index: 1;
		font-size: .7em;
		display: block;
		border: 0;
		background-position: 0 0;
	}

    .test__gifts.not-active {
        width: 0;
    }

    .test__gifts-subtitle {
        text-shadow: 0 1px 0 rgba(255, 251, 233, 0.8);
        color: #000000;
        font-size: 19px;
        margin-bottom: 7.6px;
        font-weight: 900;
    }

    .test__gifts-title {
        text-shadow: 0 1px 0 rgba(255, 251, 233, 0.8);
        color: #000000;
        font-size: 40px;
        font-weight: 900;
        text-transform: uppercase;
        margin-bottom: 38px;
        line-height: 1.2;
    }

    .test__gifts-img {
        margin-bottom: 11.4px;
    }

    .test-inp-wrap {
        width: 21%;
        margin-right: 3%;
    }

    .test-inp {
        margin-bottom: 21px;
    }

    .test-block-img {
        width: 67%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        height: 365px;
    }

    .test-block-img img {
        -webkit-transform: scale(1.2);
        -ms-transform: scale(1.2);
        transform: scale(1.2);
    }

    .q-4 {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        border: 2px solid #96c3e6;
        border-radius: 4px;
        padding: 57px 38px;
    }

    .inp-hiden {
        display: none;
    }

    .test-inp-wrap-big {
        width: 47%;
    }

    .test-file-wrap {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }

    .file-link {
        color: #000000;
        font-size: 17px;
        border-radius: 25px 0 0 25px;
        border: 2px solid #ecf1f9;
        padding: 19px;
        overflow-x: hidden;
        min-height: 68.4px;
        width: 60%;
        white-space: nowrap;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
    }

    .file-btn {
        color: #ffffff;
        font-size: 17px;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 0.8px;
        border-radius: 0 25px 25px 0;
        background-color: #166aac;
        min-height: 68.4px;
        padding: 19px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        width: 40%;
        margin-left: -2px;
        cursor: pointer;
    }

    .q-5 {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        border-radius: 4px;
        border: 2px solid #96c3e6;
        height: 501px;
        padding: 57px;
    }

    .test-block-proc {
        width: 52%;
        text-align: center;
    }

    .test-big-num {
        background-image: -webkit-gradient(
            linear,
            left bottom,
            left top,
            from(rgba(22, 106, 172, 0)),
            to(#166aac)
        );
        background-image: -webkit-linear-gradient(
            bottom,
            rgba(22, 106, 172, 0) 0%,
            #166aac 100%
        );
        background-image: -o-linear-gradient(
            bottom,
            rgba(22, 106, 172, 0) 0%,
            #166aac 100%
        );
        background-image: linear-gradient(
            to top,
            rgba(22, 106, 172, 0) 0%,
            #166aac 100%
        );
        color: #ffffff;
        font-size: 209px;
        font-weight: 900;
        line-height: 1;
        letter-spacing: -19px;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .test-big-num span {
        font-size: 152px;
    }

    .test-big-num-title {
        color: #000000;
        font-size: 17px;
        font-weight: 900;
        text-transform: uppercase;
        letter-spacing: 0.8px;
    }

    .test-elem-lg {
        width: 30.1%;
    }

    .img-label-gift {
        width: 100%;
        border-radius: 4px 4px 0 0;
        background-color: #edf7fd;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        padding: 19px;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .test-elem-lg .img-label-gift {
        height: 304px;
    }

    .text-price {
        color: #166aac;
        font-size: 19px;
        font-weight: 900;
        white-space: nowrap;
    }

    .test-gift-wrap {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }

    .test-gift-price {
        margin-left: 38px;
        text-align: right;
    }

    .load-text {
        color: #212121;
        font-size: 22.8px;
        font-weight: 600;
    }

    .troe {
        white-space: nowrap;
        overflow-x: hidden;
        display: inline-block;
    }

    .load-text {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-animation: opas 2.5s infinite linear;
        animation: opas 2.5s infinite linear;
    }

    .load {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        flex-wrap: wrap;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .load-img {
        width: 76px;
        margin-bottom: 19px;
        -webkit-animation: deg 2.5s infinite linear;
        animation: deg 2.5s infinite linear;
    }

    .q-7 {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }

    .q-7 .test-block-img {
        width: 46%;
    }

    .q-7 .test-inp-wrap-big {
        width: 41%;
    }

    .final-label {
        border-radius: 32px;
        border: 2px solid #ecf1f9;
        padding: 15.2px;
        margin-bottom: 19px;
        margin-left: 0;
    }

    .inp-final {
        position: relative;
        padding-left: 57px;
    }

    .inp-final:focus {
        border: 2px solid #96c3e6;
    }

    .final-inp-be {
        position: relative;
        z-index: 7;
    }

    .final-inp-be:before {
        content: "";
        position: absolute;
        left: 19px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        width: 26.6px;
        height: 17px;
        background: url("../img/test/Phone_copy.png") no-repeat center;
        background-size: 100% 100%;
        z-index: 2;
    }

    .final__btn-wrap {
        position: relative;
    }

    .final__btn-wrap:before {
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        -webkit-transform: translate(130%, -50%);
        -ms-transform: translate(130%, -50%);
        transform: translate(130%, -50%);
        width: 133px;
        height: 133px;
        background: url("../img/test/Shape.png") no-repeat center;
        background-size: 100% 100%;
        z-index: 2;
    }

    .final__btn-wrap .btn-text {
        color: #000000;
    }

    .final__btn-wrap .btn {
        width: 100%;
        min-height: 85.5px;
        padding: 19px;
        margin-top: 28.5px;
    }

    .test__gifts-price {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }

    .test__gifts-price .text-price {
        color: #000000;
    }

    .test__gifts-price .text-small {
        color: #000000;
    }

    .test__gifts-name {
        text-shadow: 0 1px 0 rgba(255, 251, 233, 0.8);
        color: #000000;
        font-size: 19px;
        font-weight: 500;
        text-align: left;
        line-height: 1.4;
    }

    .qw3-block-title {
		font-weight: 500;
		font-size: 20px;
		text-transform: uppercase;
		margin-top: 15px;
    }

    .qw3-block-subtitle {
		font-size: 14px;
        margin-top: 9.5px;
        margin-bottom: 47px;
    }

    .qw3-label {
		font-size: 10px;
		opacity: 0.8;
        margin-bottom: 9.5px;
        display: block;
    }

    .qw3 .test__btn-block {
		width: 60%;
		margin: 10px auto 0;
        justify-content: flex-start;
	}
	
	.answer-wrap {
		display: flex;
		justify-content: space-between;
		width: 100%;
		flex-wrap: wrap;
	}

	.check-item {
		width: 30%;
		padding: 13px 8px;
		margin: 0 0 20px;
		display: flex;
		align-items: center;
		min-height: 60px;

		.qw1-bg {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background-color: #ffffff;
			border-radius: 4px;
			border: 1px solid #a8a3a1;
			transition: all 0.4s;
			background-image: none;
		}

		.radio-label {
			margin: 0;
			text-align: left;
		}
	}

	.predfinals .left-block {
		padding-top: 35px;
	}

	.gift-block {
		margin-top: 10px;
	}

    .qw5-bg + .radio-label {
        margin-top: -57px;
        display: block;
        width: 228px;
    }

    .qw5-bg {
        width: 228px;
        height: 209px;
        max-width: 234px;
        max-height: 206px;
        border: 1px solid #c4c4c4;
        border-radius: 5px;
        background-repeat: no-repeat;
        background-position: center top 40px;
    }

    .qw5-bg + .radio-label span {
        color: #525252;
        font-size: 11.4px;
        display: block;
    }

    .qw5 .test_elem {
        width: 30%;
    }

    .test_elem:hover .bg-qw1 {
        border: 1px solid #FFCC16 !important;
        box-shadow: 0 0 30px rgba(250, 236, 21, 0.15);
    }

    .bg-qw1 {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-image: -webkit-gradient(
            linear,
            left top,
            right top,
            from(#ffffff),
            to(rgba(255, 255, 255, 0))
        );
        background-image: -webkit-linear-gradient(
            left,
            #ffffff 0%,
            rgba(255, 255, 255, 0) 100%
        );
        background-image: -o-linear-gradient(
            left,
            #ffffff 0%,
            rgba(255, 255, 255, 0) 100%
        );
        background-image: linear-gradient(
            to right,
            #ffffff 0%,
            rgba(255, 255, 255, 0) 100%
        );
        border-radius: 4px;
        border: 1px solid #a8a3a1;
        -webkit-transition: all 0.4s;
        -o-transition: all 0.4s;
        transition: all 0.4s;
    }

    .checkbox-inp:checked + .qw1-bg,
    .checkbox-inp:checked + .qw5-bg {
        border: 3px solid #FFCC16 !important;
        box-shadow: 0 0 30px rgba(250, 236, 21, 0.15);
	}

	.range-block {
		display: flex;
		width: 65%;
		margin: 10px 0 30px;
		height: 50px;
		position: relative;
	  }
	  
	  .range-start,
	  .range-end {
		position: absolute;
		bottom: 30px;
		font-size: 10px;
	  }
	  
	  .range-start {
		left: 0;
	  }
	  
	  .range-end {
		right: 0;
	  }
	  
	  .range-slider {
		width: 100%;
	  }
	  
	  .range-slider.ui-widget.ui-widget-content {
		background-color: #D7D7D7;
		border: none;
		height: 4px;
	  }
	  
	  .range-slider.ui-widget.ui-widget-content span:nth-child(3) {
		background: radial-gradient(50% 7.43% at 50% 100%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), radial-gradient(67.05% 156.76% at 50% -16.22%, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(180deg, #FFDF6C 0%, rgba(255, 255, 255, 0) 5.73%), linear-gradient(180deg, #FFCC16 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(180deg, rgba(92, 72, 0, 0) 85.94%, rgba(255, 247, 174, 0.142) 100%), #FF9900;
		width: 16px;
		height: 16px;
		border-radius: 50%;
		border: none;
		margin-top: -2px;
		outline: none;
	  }

	  .range-slider.ui-widget.ui-widget-content span:nth-child(2) {
		  display: none;
	  }
	  
	  .range-slider.ui-slider .ui-slider-range {
		background: radial-gradient(50% 7.43% at 50% 100%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), radial-gradient(67.05% 156.76% at 50% -16.22%, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(180deg, #FFDF6C 0%, rgba(255, 255, 255, 0) 5.73%), linear-gradient(180deg, #FFCC16 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(180deg, rgba(92, 72, 0, 0) 85.94%, rgba(255, 247, 174, 0.142) 100%), #FF9900;
	  }
	  
	  .range-slider span:nth-child(2):before {
		  content: none;
	  }
	  
	  .range-slider span:nth-child(3):before {
		content: attr(value);
		position: absolute;
		bottom: -45px;
		width: 70px;
		text-align: center;
		padding: 7px;
		border: 1px solid rgba(0, 0, 0, 0.2);
		border-radius: 5px;
		left: -28px;
		font-size: 14px;
		background-color: #ffffff;
		color: #2E2E2E;
	  }
	
	.big-title-test {
		font-weight: 600;
		font-size: 18px;
		margin-bottom: 10px;
	}

	.pred-pdf {
		font-size: 12px;
		flex: 0 0 auto;
	}

    .test-item-block.qw3-block {
        justify-content: flex-start;
        margin: 0 auto;
    }

    .predfinal__img {
        position: absolute;
        right: 76px;
        bottom: 57px;
        max-width: 551px;
    }

    .predfinal__block {
        margin-bottom: 57px;
    }

    .predfinal .radio-label {
		justify-content: flex-start;
	}

	.predfinal .qw3-check.radio-label {
		font-size: 12px;
    }

    .predfinal__form {
        display: flex;
        align-items: center;
    }

    .predfinal__form > div {
        position: relative;
    }

    .pred {
        flex: 1 0 auto;
    }

    .btn-predfinal {
		width: 180px;
		margin: 0 20px;
		flex: 0 0 auto;
    }

    .qw3-check.radio-label {
        margin: 38px 0;
        text-align: left;
    }

    .test-elem-7 {
        width: 100%;
        margin: 0;
        height: auto;
        min-height: 19px;
        line-height: 1.1;
        padding: 0;
    }

    .btn-test {
        border-radius: 15px;
        width: 175px;
        -webkit-transition: all 0.4s;
        -o-transition: all 0.4s;
        transition: all 0.4s;
        min-height: 48px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        text-transform: uppercase;
        justify-content: center;
    }

    .btn-test:disabled {
        opacity: 0.4;
    }

    .btn-arr {
        font-size: 32px;
        display: inline-block;
        line-height: 0.8;
        margin-left: 13px;
        font-weight: 700;
        color: #000000;
        margin-top: -3px;
    }

    .test__btn-block {
        display: flex;
        justify-content: flex-end;
    }

    .test__numbers {
        position: absolute;
        bottom: 0;
        left: 38px;
        color: #f3f3f3;
        font-size: 171px;
        font-weight: 900;
        -webkit-transform: translateY(38%);
        -ms-transform: translateY(38%);
        transform: translateY(38%);
    }

    .test__qwiz {
        position: relative;
    }
}

@media (max-width: 800px) {
	.snow-quiz-modal {
		height: 100%;

		.left-block {
			display: none;
		}

		.test-wrapper.predfinals {
			overflow-y: scroll;
		}

		.test-wrapper {
			overflow-y: scroll;
			height: 100%;
		}

		.progress-bar__title {
			font-size: 14px;
			margin: 15px 0 10px;
		}

		.test__main {
			padding: 10px 10px 20px 10px;
			width: 100%;
			height: initial;

			&.finals {
				background: none;
			}
		}

		.left-block-title {
			font-weight: 500;
			font-size: 18px;
		}

		.test_elem {
			width: 48%;
			margin: 0 0 15px;
		}

		.qw1-bg {
			width: 100%;
			background-size: cover;
			height: 146px;
		}

		.radio-label {
			font-size: 14px;
			font-weight: 500;
		}

		.test-item-block {
			justify-content: space-between;
		}

		.test__btn-block {
			justify-content: center;
		}

		.range-block {
			width: 100%;
		}

		.qw5-bg {
			width: 100%;
			height: 180px;
		}

		.qw5-bg+.radio-label {
			font-size: 11px;
			width: 100%;
			margin-top: -46px;

			span {
				font-size: 8px;
			}
		}

		.qw5 .test_elem {
			width: 48%;
			margin: 0 0 20px;
		}

		.mb-1 {
			width: 70%;
			margin: -40px auto 0;
		}

		.predfinal {
			.test_elem {
				width: 100%;
			}

			.radio-label {
				text-align: left;
			}
		}

		.qw3-check.radio-label {
			margin: 0;
		}

		.predfinal__block {
			margin-bottom: 20px;
		}

		.predfinal__form {
			flex-direction: column;
			align-items: baseline;
		}

		.btn-predfinal {
			margin: 10px 0;
			width: 235px;
		}

		.pred-pdf {
			text-align: center;

			 br {
				 display: none;
			 }
		}
	}
}