.lexa {
	&__container {
		position: relative;
	}

	&-title {
		display: flex;
		justify-content: center;
		flex-direction: column;
		background: url('/images/quote.png') no-repeat left center;
		height: 97px;
		font-size: 30px;
		padding-left: 70px;
		
		i {
			font-weight: 500;
		}
	}

	&-name {
		position: absolute;
		right: 75px;
		top: 120px;
		font-size: 12px;
		text-align: right;
		color: #714F3C;
	}

	&-about {
		width: 564px;
		padding: 65px 60px 60px 60px;
		background: url('/images/lexa-about.png') no-repeat top center;
	
		&-text {
			font-size: 14px;
		}

		&-i {
			font-weight: 500;
			font-style: italic;
		}
	}

	&__about {
		margin: 20px 0 0 95px;
	}

	&-experience {
		display: flex;
		color: #714F3C;
		width: 177px;

		&-block {
			display: flex;
			justify-content: space-around;
		}

		&__block {
			margin-top: 90px;
		}

		&__desc {
			margin-top: 15px;
		}

		&__count {
			font-weight: bold;
			font-size: 90px;
			margin-right: 5px;

			&-title {
				font-weight: bold;
				font-size: 48px;
				margin-top: -15px;

				&-small {
					font-weight: bold;
					font-size: 30px;
					margin-top: -5px;
				}
			}
		}
	}
}

@media (max-width: 800px) {
	.lexa {
		&-title {
			font-size: 24px;
			text-align: center;
			padding: 0 20px;
			background: none;
		}

		&-about {
			width: 320px;
			margin: 20px 0 0 0;
    		padding: 65px 25px 60px;
    		background: url(/images/lexa-about-m.png) no-repeat top center;
		}

		&-name {
			right: -45px;
			bottom: -105px;
			top: initial;
			font-size: 10px;
		}

		&-experience {
			&__count {
				font-size: 80px;

				&-title {
					font-size: 35px;
					margin-top: -7px;

					&-small {
						font-size: 24px;
    					margin-top: -2px;
					}
				}
			}
			&__desc {
				font-size: 14px;
				margin-top: 18px;
			}
			&__block {
				margin: 45px -25px 0 -10px;
			}
		}
	}
}

@media (max-width: 480px) {
	.lexa {
		&-name {
			right: 0;
		}
	}
}