.call-modal {
	width: 824px;
	height: 623px;
	background: url('/images/call-modal_bg.png') no-repeat center;
	padding: 190px 204px 50px 317px;
}

@media (max-width: 800px) {
	.call-modal {
		width: 320px;
		height: 539px;
		background: none;
		padding: 185px 25px 65px;

		&:before {
			content: '';
			position: absolute;
			width: 508px;
			height: 539px;
			background: url('/images/call-modal_bg-m.png') no-repeat center;
			z-index: -1;
			left: -85px;
			top: 3px;
			margin: auto;
		}

		.modal__close {
			top: 130px;
		}
	}
}