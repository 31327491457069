.stocks {
	&-swiper {
		&__item {
			width: 562px;
			height: 347px;
			background: radial-gradient(187.5% 145.53% at 47.86% 10.66%, rgba(196, 196, 196, 0.2) 0%, rgba(255, 255, 255, 0) 100%), #FDFDFD;
			box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
			border-radius: 10px;
			padding: 50px 50px 50px 0;
			display: flex;
			align-items: center;
			transform: scale(0.65);
			transition-duration: 0.5s;
			opacity: 0.7;

			&-title {
				font-weight: bold;
				font-size: 32px;
				margin-right: -10%;
			}

			&-subtitle {
				font-style: italic;
				font-size: 25px;
			}

			&-desc {
				font-size: 18px;
				margin: 20px 0 40px;
				
				span {
					color: #CD8A20;
				}
			}

			&-right {
				width: 54%;
				flex: 1 0 auto;
				padding-right: 4%;
			}

			&.swiper-slide-active {
				opacity: 1;
				transform: scale(1);
				z-index: 1;
			}

			&.swiper-slide-prev {
				transform: scale(0.65) translate(44%, 0);
			}

			&.swiper-slide-next {
				transform: scale(0.65) translate(-44%, 0);
			}
		}
	}

	&__swiper {
		padding: 30px;
		margin: 66px -30px 0;
	}
}

@media (max-width: 800px) {
	.stocks {
		&__swiper {
			margin: 0 -20px;
		}

		&-swiper {
			&__item {
				width: 320px;
				height: 176px;
				padding: 20px 15px;
				transition: none;

				img {
					position: absolute;
					margin: 0 !important;
					width: 100px;
					height: 100px;
					object-fit: contain;
					right: 0;
					top: 0;
				}

				&-title {
					font-size: 21px;
				}

				&-subtitle {
					font-size: 18px;
				}

				&-desc {
					font-size: 15px;
					margin: 10px 30px 20px 25px;
				}

				&-right {
					width: 100%;
					padding: 0;
				}

				&-btn {
					width: 175px;
					height: 34px;
					font-size: 14px;
					position: absolute;
				}

				&.swiper-slide-prev {
					transform: scale(1) translate(-13%, 0);
				}
	
				&.swiper-slide-next {
					transform: scale(1) translate(13%, 0);
				}
			}
		}
	}
}