.frame {
	width: 45px;
	height: 142px;
	background: radial-gradient(126.68% 11.66% at 48.89% 104.23%, #FFFFFF 0%, rgba(255, 255, 255, 0) 68.49%), radial-gradient(67.05% 156.76% at 50% -16.22%, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(180.19deg, #202020 -3.01%, rgba(255, 255, 255, 0) 2.88%), linear-gradient(180deg, #202020 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(180deg, rgba(92, 72, 0, 0) 85.94%, rgba(255, 247, 174, 0.142) 100%), #202020;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 2px 0px #2E2E2E;
	border-radius: 5px;
	position: fixed;
	right: 10px;
	top: 0;
	bottom: 0;
	margin: auto 0;
	z-index: 10;

	&__item {
		width: 100%;
		height: 46px;
		cursor: pointer;
		position: relative;

		&:nth-child(1) {
			background: url('/images/frame-item-1.png') no-repeat center;
		}

		&:nth-child(3) {
			background: url('/images/frame-item-2.png') no-repeat center;
		}

		&:nth-child(5) {
			background: url('/images/frame-item-3.png') no-repeat center;
		}

		&:hover {
			.frame__hover { 
				left: -187px;
				opacity: 1;
				height: 55px;

				& > * {
					right: 0;
				}
			}
		}
	}

	&__line {
		height: 2px;
		width: 100%;
		background: radial-gradient(50% 7.43% at 50% 100%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), radial-gradient(67.05% 156.76% at 50% -16.22%, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(180deg, #FFDF6C 0%, rgba(255, 255, 255, 0) 5.73%), linear-gradient(180deg, #FFCC16 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(180deg, rgba(92, 72, 0, 0) 85.94%, rgba(255, 247, 174, 0.142) 100%), #FF9900;
		box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
	}

	&__hover {
		position: absolute;
		width: 187px;
		display: flex;
		justify-content: flex-end;
		overflow: hidden;
		height: 100%;
		z-index: -1;
		overflow: hidden;
		opacity: 0;
		left: 0;

		.btn {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 44px;
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
			position: absolute;
			right: -187px;
			text-decoration: none;
		}
	}

	&__social {
		background: radial-gradient(50% 7.43% at 50% 100%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), radial-gradient(67.05% 156.76% at 50% -16.22%, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(180deg, #FFDF6C 0%, rgba(255, 255, 255, 0) 5.73%), linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(180deg, rgba(92, 72, 0, 0) 85.94%, rgba(255, 247, 174, 0.142) 100%), #D8D8D8;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 2px 0px #DFDFDF;
		border-top-left-radius: 5px;
		border-bottom-left-radius: 5px;
		width: 160px;
		height: 44px;
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		right: -160px;
		transition: right 0.3s;

		&-item {
			width: 28px;
			height: 28px;
			background-position: center;
			background-repeat: no-repeat;
			margin-right: 5px;
			box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
			border-radius: 100%;

			&:last-child {
				margin-right: 0;
			}

			&._inst {
				background-image: url('/images/frame-inst.png');
			}

			&._tg {
				background-image: url('/images/frame-tg.png');
			}

			&._vk {
				background-image: url('/images/frame-vk.png');
			}

			&._whatsapp {
				background-image: url('/images/frame-whatsapp.png');
			}
		}
	}
}

@media (max-width: 800px) {
	.frame {
		right: 0;
	}
}