.reviews {
	background: url(/images/review-back.png) no-repeat center;
	height: 1065px;

	&-title {
		font-weight: bold;
		font-size: 36px;
		text-align: center;
	}

	&-subtitle {
		font-style: italic;
		font-size: 26px;
		text-align: center;
	}

	&__swiper {
		margin-top: 70px;

		&-item {
			width: 1200px;
			height: 660px;
			background: url('/images/review-bg.png') no-repeat center;
			padding: 120px;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		&-prev {
			left: 2px;
			margin-top: -10px;
		}

		&-next {
			right: 2px;
			margin-top: -10px;
		}
	}

	&__pagination {
		bottom: 97px;
	}

	.swiper-pagination-bullet {
		width: initial;
		height: initial;
		opacity: 1;
		background: none;
		color: #616161;
		border-bottom: 1px solid #616161;
		border-radius: 0;
		margin: 0 25px;
		cursor: pointer;

		&._active {
			font-weight: 600;
		}
	}
}

.page__reviews {
	margin-top: 150px;
}

#cboxCurrent {
	display: none !important;
}

#cboxPrevious {
	background: url('/images/controls.png') no-repeat top left;
}

#cboxNext {
    background: url('/images/controls.png') no-repeat top right;
}

#cboxClose {
    background: url('/images/controls.png') no-repeat top center;
}

@media (max-width: 800px) {
	.reviews {
		background: url(/images/review-back-m.png) no-repeat center;
		height: 881px;

		.swiper-container {
			margin: 50px -18px 0;

			&._active {
				display: block;
			}
		}

		&__swiper {
			&-item {
				padding: 70px;
				width: 380px;
				height: 499px;
				background: url(/images/review-bg-m.png) no-repeat center;
			
				img {
					object-fit: contain;
					width: 100%;
					height: 100%;
				}
			}

			&-prev.swiper-button-prev,
			&-next.swiper-button-next {
				top: 50%;
				margin-top: -23px;
				right: 3px;
			}

			&-prev.swiper-button-prev {
				left: 3px;
			}
		}

		.swiper-pagination {
			padding: 0 30px;

			&-bullet {
				font-size: 10px;
				margin: 0;
				width: 92px;
				border: none;
				text-decoration: underline;
	
				&:first-child {
					text-align: left;
				}
	
				&:last-child {
					text-align: right;
				}
			}
		}

		&__mobile {
			position: relative;

			&-pagination {
				display: flex;
				width: 100%;
				padding: 0 20px;
				justify-content: space-between;
				position: absolute;
				bottom: 53px;
				z-index: 1;

				&-item {
					font-size: 11px;
					text-decoration: underline;

					&:nth-child(2) {
						text-align: center;
					}

					&:nth-child(3) {
						text-align: right;
					}

					&._active {
						font-weight: 600;
					}
				}
			}
		}
	}

	.page__reviews {
		margin-top: 125px;
	}

	#colorbox,
	#cboxContent,
	#cboxWrapper {
		width: 100% !important;
	}

	#cboxLoadedContent {
		width: 100% !important;
		box-sizing: border-box;
	}

	.cboxPhoto {
		width: 100% !important;
		object-fit: cover;
	}
}