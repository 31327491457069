.brigade-quiz-modal {
	.bqw3 .test__btn-block {
		width: 75%;
		margin: 38px auto 0;
		justify-content: flex-start;
	}
	
	.test-item-block.bqw3-block {
		justify-content: flex-start;
		width: 75%;
		margin: 0 auto;
	}
	
	.test {
		padding-bottom: 47.5px;
	}
	
	.test-wrapper {
		-webkit-box-shadow: 0 0 30px rgba(152, 152, 152, 0.1);
		box-shadow: 0 0 30px rgba(152, 152, 152, 0.1);
		background-color: #ffffff;
		overflow: hidden;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		width: 100%;
		height: 100%;
	}
	
	.test-wrapper.predfinals {
		overflow: unset;
	}
	
	.test__main {
		width: 77%;
		padding: 35px 25px;
		position: relative;
	}
	
	.test__main.final {
		width: 100%;
	}
	
	.left-block {
		width: 23%;
		-ms-flex-negative: 0;
		flex-shrink: 0;
		padding: 10px;
		background: url("/images/btest-bg.jpg") no-repeat center;
		background-size: cover;
		height: 500px;
		text-align: center;
	}
	
	.left-block-subtitle {
		color: #8c8c8c;
		font-size: 16px;
		font-weight: 400;
		text-transform: uppercase;
		margin-bottom: 5px;
	}

	.test_elem.xz {
		width: 200px;
		margin: 15px 0 -20px 15px;
		padding: 1px 10px 10px 10px;
	}
	
	.left-block-title {
		color: #2E2E2E;
		font-size: 20px;
		text-transform: uppercase;
		font-weight: bold;
	}
	
	.left-block-title .trigger {
		color: #e0ba1f;
		font-style: italic;
		font-weight: 700;
	}
	
	.left-block.no-show {
		overflow: hidden;
		width: 0;
		padding: 0;
	}

	.progress-bar {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		width: 75%;
		margin: 0 auto;
		margin-bottom: 15px;
	}
	
	.progress-bar__title {
		color: #a8a3a1;
		font-size: 14px;
		-ms-flex-negative: 0;
		flex-shrink: 0;
		margin-bottom: 19px;
	}
	
	.progress-bar__line {
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 19%;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-shadow: inset 0 0 20px rgba(233, 227, 49, 0.2);
		box-shadow: inset 0 0 20px rgba(233, 227, 49, 0.2);
		background-color: #FFEB3B;
		background-image: -webkit-gradient(linear, left top, right top, from(rgba(220, 222, 88, 0.8)), to(rgba(88, 222, 92, 0))), repeating-linear-gradient(-55deg, #f4d532, transparent 6px, #f4d532 5px, #f4d532 5px);
		background-image: -webkit-linear-gradient(left, rgba(220, 222, 88, 0.8) 0%, rgba(88, 222, 92, 0) 100%), -webkit-repeating-linear-gradient(145deg, #f4d532, transparent 6px, #f4d532 5px, #f4d532 5px);
		background-image: -o-linear-gradient(left, rgba(220, 222, 88, 0.8) 0%, rgba(88, 222, 92, 0) 100%), -o-repeating-linear-gradient(145deg, #f4d532, transparent 6px, #f4d532 5px, #f4d532 5px);
		background-image: linear-gradient(to right, rgba(220, 222, 88, 0.8) 0%, rgba(88, 222, 92, 0) 100%), repeating-linear-gradient(-55deg, #f4d532, transparent 6px, #f4d532 5px, #f4d532 5px);
		-webkit-animation: progres 2.8s linear infinite;
		animation: progres 2.8s linear infinite;
		-webkit-transition: width 0.4s;
		-o-transition: width 0.4s;
		transition: width 0.4s;
	}
	
	.progress-bar__line-wrap {
		height: 8px;
		-webkit-box-shadow: 0 0 30px rgba(183, 193, 199, 0.1);
		box-shadow: 0 0 30px rgba(183, 193, 199, 0.1);
		border-radius: 5px;
		background-color: #e7e7e7;
		width: 100%;
		overflow: hidden;
		position: relative;
	}
	
	@-webkit-keyframes progres {
		from {
			background-position: 0 0, 0 0;
		}
	
		to {
			background-position: 0 0, 60px 30px;
		}
	}
	
	@keyframes progres {
		from {
			background-position: 0 0, 0 0;
		}
	
		to {
			background-position: 0 0, 60px 30px;
		}
	}
	
	.checkbox-psevdo {
        width: 24px;
        height: 24px;
		border-radius: 3px;
		border: 1px solid #a8a3a1;
		background-color: #ffffff;
        padding: 3.8px;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		margin-right: 13.3px;
		-ms-flex-negative: 0;
		flex-shrink: 0;
		position: initial;
		top: 10px;
		left: 10px;
	}
	
	.first-wrapper {
		display: flex;
		justify-content: space-between;
		width: 100%;
		padding: 0 19px;
	}
	
	.first-wrapper__column-1 {
		width: 192px;
	}
	
	.test__main.bqw3 {
		background: url(/images/3-bg.jpg) no-repeat right bottom;
	}
	
	.btn {
		width: 247px;
		margin: 0 15px;
	}
	
	.modal form {
		padding: 0;
		margin: 0;
	}
	
	.checkbox-psevdo-active {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-transform: scale(0);
		-ms-transform: scale(0);
		transform: scale(0);
		-webkit-transition: all 0.3s;
		-o-transition: all 0.3s;
		transition: all 0.3s;
	}
	
	.checkbox-psevdo-active img {
		width: 140%;
		max-width: 140%;
		-ms-flex-item-align: center;
		align-self: center;
	}
	
	.checkbox-inp:checked ~ .radio-label {
		color: #2a2a2a;
	}
	
	.checkbox-inp:checked ~ label .checkbox-psevdo-active {
		-webkit-transform: scale(1);
		-ms-transform: scale(1);
		transform: scale(1);
	}
	
	.radio-wrap {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		position: relative;
		margin-top: 2vh;
		margin-bottom: 3vh;
		width: 100%;
		font-weight: 500;
	}
	
	.radio-item {
		width: 45%;
		text-align: left;
	}
	
	.bqw-4 .test_elem {
		width: 30%;
		display: flex;
		align-items: center;
		padding: 19px;
	}
	
	.bqw-4 .checkbox-wrap {
		padding: 0;
	}
	
	.bqw-4 .test-elem-1 .checkbox-psevdo {
		position: initial;
	}
	
	.bqw-4 .radio-label {
		margin-bottom: 10px;
	}
	
	.bqw4-bg {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: #ffffff;
		border-radius: 4px;
		border: 1px solid #a8a3a1;
		-webkit-transition: all 0.4s;
		-o-transition: all 0.4s;
		transition: all 0.4s;
	}
	
	.radio-label {
		color: #000000;
		font-size: 16px;
		display: inline-block;
		position: relative;
		-webkit-transition: all 0.3s;
		-o-transition: all 0.3s;
		transition: all 0.3s;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		cursor: pointer;
		text-align: center;
		justify-content: center;
		margin-top: 9.5px;
	}
	
	.radio-psevdo {
		border: 1px solid #a8a3a1;
		background-color: #ffffff;
        width: 22.8px;
        height: 22.8px;
        border-radius: 100%;
        -webkit-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        margin-right: 11.4px;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-flex: 0;
		-ms-flex-positive: 0;
		flex-grow: 0;
	}
	
	.radio-psevdo-active {
        width: 12.16px;
        height: 12.16px;
		background-color: #e0ba1f;
		border-radius: 100%;
		opacity: 0;
		-webkit-transition: all 0.3s;
		-o-transition: all 0.3s;
		transition: all 0.3s;
	}
	
	.radio-block {
		width: 100%;
	}
	
	.radio-btn {
		display: none;
	}
	
	.radio-btn:checked ~ .radio-label {
		color: #2a2a2a;
	}
	
	.radio-btn:checked ~ label .radio-psevdo-active {
		opacity: 1;
	}
	
	.test-item-block {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}
	
	.test-item__title {
		color: #212121;
        font-size: 22.8px;
		font-weight: 700;
		margin-bottom: 32.3px;
	}
	
	.test-item__title span {
		color: #ffd535;
	}
	
	.img-label {
        height: 121.6px;
		width: 100%;
		display: inline-block;
		border-radius: 10px;
		cursor: pointer;
	}
	
	.test_elem {
		-webkit-transform: all 0.4s;
		-ms-transform: all 0.4s;
		transform: all 0.4s;
		border-radius: 4px;
		padding: 0 19px;
		width: 19%;
		margin: 0 1.5% 13px 1.5%;
		display: block;
		position: relative;
		cursor: pointer;
		-webkit-transition: all 0.4s;
		-o-transition: all 0.4s;
		transition: all 0.4s;
	}
	
	.test_elem-t2 {
		padding: 0;
		border-radius: 10px;
		text-align: center;
		display: block;
	}
	
	.test_elem__wrapper .radio-label {
		color: #000000;
		opacity: 1;
		position: initial;
		display: block;
		margin-top: -4px;
		padding-left: 48px;
		padding-top: 13px;
		text-align: left;
	}
	
	.first-wrapper__column-1 .test_elem__wrapper {
		background-repeat: no-repeat;
		background-position: center;
	}
	
	.first-wrapper__column-1:nth-child(1) .test-elem-1:nth-of-type(1) {
		background-image: url(/images/m-item-1.jpg);
	}
	
	.first-wrapper__column-1:nth-child(1) .test-elem-1:nth-of-type(2) {
		background-image: url(/images/m-item-5.jpg);
	}
	
	.first-wrapper__column-1:nth-child(2) .test-elem-1:nth-of-type(1) {
		background-image: url(/images/m-item-2.jpg);
	}
	
	.first-wrapper__column-1:nth-child(2) .test-elem-1:nth-of-type(2) {
		background-image: url(/images/m-item-6.jpg);
	}

	.first-wrapper__column-1:nth-child(3) .test-elem-1:nth-of-type(1) {
		background-image: url(/images/m-item-3.jpg);
	}

	.first-wrapper__column-1:nth-child(3) .test-elem-1:nth-of-type(2) {
		background-image: url(/images/m-item-7.jpg);
	}

	.first-wrapper__column-1:nth-child(4) .test-elem-1:nth-of-type(1) {
		background-image: url(/images/m-item-4.jpg);
	}

	.first-wrapper__column-1:nth-child(4) .test-elem-1:nth-of-type(2) {
		background-image: url(/images/m-item-8.jpg);
	}
	
	.bqw3-wrap.bqw1-wrap .bqw3-block-subtitle {
		margin-bottom: 28px;
	}

	.bqw3-wrap.bqw1-wrap {
		position: absolute;
		left: -225px;
		bottom: 40px;
	}

	.bqw3-block-title {
		font-weight: 600;
		font-size: 18px;
		text-transform: uppercase;
	}
	
	.bqw3-block-subtitle {
		font-size: 14px;
		margin-top: 10px;
		margin-bottom: 57px;
		text-transform: uppercase;
	}
	
	.bqw3-label {
        font-size: 12.73px;
        margin-bottom: 9.5px;
		display: block;
	}
	
	.bqw3-check.radio-label {
		margin: 38px 0;
		text-align: left;
	}

	.test-text-wrap {
		width: 100%;
		text-align: center;
		margin-bottom: 30px;
	}

	.controlgroup {
		position: relative;
		width: 96px;
		margin-left: 30px;
		font-weight: 600;
		font-size: 24px;
		color: rgba(60,55,54,0.8);
	}

	.ui-spinner-up:before {
		content: '+';
		color: #3C3736;
		font-weight: bold;
		font-size: 30px;
		position: absolute;
		right: -14px;
		top: 8px;
		cursor: pointer;
	}
	
	.ui-spinner-down:before {
		content: '-';
		color: #3C3736;
		font-weight: bold;
		font-size: 30px;
		position: absolute;
		left: -24px;
		top: 6px;
		cursor: pointer;
	}

	.ui-spinner-input {
		width: 85px;
		background: #F7F7F7;
		box-shadow: inset 0px 0px 3px rgba(0,0,0,0.1);
		border-radius: 10px;
		border: none;
		height: 50px;
		font-size: 16px;
		padding: 15px 20px;
		text-align: center;
	}
	
	.test_elem__wrapper .test_elem {
		width: 100%;
		padding: 0;
		margin: 0;
		border-radius: 5px;
		height: 80px;
	}

	.bqw5-bg {
		width: 100%;
		height: 206px;
		border: 1px solid #C4C4C4;
		border-radius: 5px;
		background-repeat: no-repeat;
		background-position: top;
	}

	.bqw5 .test_elem {
		width: 30%;
	}

	.bqw5-bg + .radio-label span {
		color: #525252;
        font-size: 11.4px;
		display: block;
	}

	.bqw5-bg + .radio-label {
		margin-top: -57px;
		display: block;
	}

	.gift-block-title {
		color: #535353;
		font-size: 18px;
		font-weight: 400;
		text-transform: uppercase;
	}

	.pred {
		flex: 1 0 auto;
	}

	.predfinal__form .btn {
		width: 532px;
		min-height: 57px;
	}

	.predfinal__form input {
		padding: 11.4px 41.8px;
		min-height: 57px;
	}

	.predfinal__form {
		display: flex;
		align-items: center;
	}

	.pred-pdf {
		color: #2E2E2E;
		font-size: 15.2px;
	}

	.gift-block {
		padding: 19px 38px 38px;
		width: 100%;
		position: relative;
		-webkit-transform: scale(1.1);
		-ms-transform: scale(1.1);
		transform: scale(1.1);
	}

	.big-title-test {
		font-weight: 600;
		font-size: 18px;
		margin-bottom: 10px;
	}

	.predfinal .radio-label {
		justify-content: flex-start;
		margin: 0 0 10px;
	}

	.predfinal .bqw3-check.radio-label {
		margin: 26px 0 50px;
		font-size: 12px;
	}

	.test-elem-7 {
		width: 100%;
		margin: 0;
		height: auto;
		min-height: 19px;
		line-height: 1.1;
		padding: 0;
	}

	.predfinal .left-block-title {
		font-size: 14px;
	}

	.predfinal .test-text-wrap {
		text-align: left;
	}

	.predfinal__img {
		position: absolute;
		right: 50px;
		bottom: 68px;
		max-width: 551px;
	}
	
	.test-elem-1 .checkbox-psevdo {
		position: absolute;
	}
	
	.test__main.bqw3 {
		background: url(/images/b3-bg.jpg) no-repeat right bottom;
	}
	
	.test__main.bqwlast {
		background: url(/images/blast-bg.jpg) no-repeat right bottom;
	}
	
	.bqw3-wrap.bqw1-wrap {
		position: absolute;
		left: -225px;
		bottom: 40px;
	}
	
	.bqw3-wrap.bqw1-wrap .bqw3-block-subtitle {
		margin-bottom: 28px;
	}
	
	label[for="bqw5-1"] .bqw5-bg {
		background-image: url(/images/t5-1.jpg);
		background-position-y: 18px;
		background-size: 132px;
	}
	
	label[for="bqw5-2"] .bqw5-bg {
		background-image: url(/images/t5-2.jpg);
		background-position-y: 20px;
		background-size: 158px;
	}
	
	label[for="bqw5-3"] .bqw5-bg {
		background-image: url(/images/t5-3.jpg);
		background-position-y: 21px;
		background-size: 113px;
	}

	.bqw1 .test__btn-block {
		justify-content: flex-end;
	}

	.test__btn-block {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
	}
}

@media (max-width: 740px) {
	.brigade-quiz-modal {
		.test-wrapper {
			overflow-y: scroll;	
		}

		.test_elem.xz {
			margin: 15px auto -20px;
		}

		.bqw5 .test_elem {
			width: 46%;
			align-items: flex-start;
			padding: 0;
		}

		label[for="bqw5-1"] .bqw5-bg {
			background-size: 57px;
			background-position-y: 14px;
		}
	
		.bqw5-bg + .radio-label {
			font-size: 11px;
			margin-top: -44px;
		}

		.bqw5-bg {
			width: 100%;
			height: 120px;
		}

		.bqw-4 .test_elem {
			width: 100%;
			min-height: 50px;
			padding: 0 19px;
		}

		.predfinal__img {
			display: none;
			width: 0;
		}

		.mb-1 {
			width: 240px;
			margin-top: -50px;
		}

		.predfinal .radio-label {
			justify-content: flex-start;
			text-align: left;

			br {
				display: none;
			}
		}

		.predfinal .bqw3-check.radio-label {
			margin: 20px 0 10px;
			font-size: 12px;
		}

		.predfinal__form .btn {
			width: 100%;
			margin: 10px 0;
		}

		.predfinal__form {
			display: block;
		}
	
		label[for="bqw5-2"] .bqw5-bg {
			background-position-y: 18px;
			background-size: 67px;
		}
	
		label[for="bqw5-3"] .bqw5-bg {
			background-position-y: 19px;
			background-size: 48px;
		}
	
		.bqw5-bg + .radio-label span {
			font-size: 6.5px;
		}

		.bqw3-wrap.bqw1-wrap {
			position: relative;
			margin: -40px 0 0;
			left: 0;
			bottom: 0;
		}

		.bqw3-block-title {
			font-size: 14px;
		}

		.controlgroup {
			margin: 15px auto 0;
		}

		.bqw3-wrap.bqw1-wrap .bqw3-block-subtitle {
			font-size: 9px;
			margin: 0 0 10px;
		}

		.bqw1 .test-elem-1 {
			display: block;
			background-position: 0 center;
			background-repeat: no-repeat;
			background-size: cover;
		}

		.bqw3-label {
			font-size: 8px;
			margin-bottom: 5px;
		}

		.pred-pdf {
			font-size: 10px;
			text-align: center;
			padding: 0 10px;
		}

		.left-block {
			display: none;
			width: 0;
		}

		.test__btn-block {
			margin-top: 30px;
			margin-bottom: 20px;
		}

		.test-item:nth-child(3) .test_elem {
			width: 100%;
			padding: 10px;
			height: 50px;
		}

		.bqw3-block-subtitle {
			margin-bottom: 10px;
		}

		.test-item-block.bqw3-block {
			width: 100%;
		}

		.test__main {
			width: 100%;
			padding-top: 10px;
		}

		.progress-bar {
			width: 100%;
			margin-bottom: 30px;
		}

		.test-text-wrap {
			margin-bottom: 22px;
		}

		.left-block-title {
			font-size: 14px;
			font-weight: bold;
		}

		.first-wrapper {
			flex-wrap: wrap;
			padding: 0;
		}

		.test_elem__wrapper .radio-label {
			padding-top: 10px;
		}
		
		.first-wrapper__column-1 {
			width: 49%;
		}
		
		.first-wrapper__column-2 {
			width: 49%;
		}
		
		.first-wrapper__column-3 {
			width: 49%;
		}
		
		.first-wrapper__column-4 {
			width: 49%;
		}
	
		.checkbox-psevdo {
			width: 15px;
			height: 15px;
			margin-right: 7px;
		}

		.radio-label {
			font-size: 13px;
		}
	}
}